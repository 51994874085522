import i18n from 'src/app/i18n';
import { checkBot } from './appUtil';


export enum ErrorType {
  ERROR = 'error',
  WARN = 'warn',
}

export enum ErrorSubType {
  SERVER_ERROR = 'server_error',
  CLIENT_ERROR = 'client_error',
  CLIENT_ERROR_ERROR = 'client_error_error',
  CLIENT_ERROR_UNHANDLEDREJECTION = 'client_error_unhandledrejection',
}

export interface ErrorData {
  url?: string;
  type: ErrorType;
  sub_type: ErrorSubType;
  content: string;
  description: string;
}

export const fetchWithErrorHandling = (errorData: ErrorData) => {

  const appState = localStorage.getItem('app-state');
  let user_id = '';
  let user_name = '';
  if (appState) {
    try {
      const appStateData = JSON.parse(appState);
      if (appStateData && appStateData.credential) {
        user_id = appStateData.credential.user_id;
        user_name = appStateData.credential.user_name;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const body = {
    url: errorData.url,
    type: errorData.type,
    sub_type: errorData.sub_type,
    "report_source": "client",
    env: process.env.REACT_APP_ENV,
    client: {
      platform: 'web',
      language: i18n.language,
      content: errorData.content,
      description: errorData.description,
    },
    user_info: {
      user_id,
      user_name,
    },
    app: "anylocal"
  };

  // 过滤bot


  if (process.env.NODE_ENV === 'development' || (process.env.REACT_APP_ENV !== 'prod' && process.env.REACT_APP_ENV !== 'prod-cn') || checkBot()) {
    console.log('report_error', body)
  } else {
    fetch('https://global-report.anylocal.cn/report_error', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).catch(e => {
      console.error(e);
    })
  }
}



export const windowErrorHandle = () => {
  try {
    window.onerror = (message: string | Event, source?: string, lineno?: number, colno?: number, error?: Error) => {

      const errorDetails = {
        message,
        source,
        lineno,
        colno,
        error: error?.stack
      }

      fetchWithErrorHandling({
        url: window.location.href,
        type: ErrorType.ERROR,
        sub_type: ErrorSubType.CLIENT_ERROR_ERROR,
        content: JSON.stringify(message),
        description: JSON.stringify({
          error: errorDetails,
          ...getExtraData()
        })
      })
      return true
    }


    window.addEventListener('error', (event) => {
      const errorDetails = {
        message: event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
        error: event.error ? event.error.toString() : null,
      };
      fetchWithErrorHandling({
        url: window.location.href,
        type: ErrorType.ERROR,
        sub_type: ErrorSubType.CLIENT_ERROR_ERROR,
        content: JSON.stringify(event.message),
        description: JSON.stringify({
          error: errorDetails,
          ...getExtraData()
        })
      })
      return true;
    });

  } catch (e) {
    console.error('window.error:', e);
  }

  window.addEventListener('unhandledrejection', (event) => {
    try {
      fetchWithErrorHandling({
        url: window.location.href,
        type: ErrorType.ERROR,
        sub_type: ErrorSubType.CLIENT_ERROR_UNHANDLEDREJECTION,
        content: JSON.stringify(event.reason),
        description: JSON.stringify(getExtraData())
      })
    } catch (e) {
      console.error('unhandledrejection:', e);
    }
  });


}


export const getExtraData = () => {
  return {
    href: window.location.href,
    userAgent: window.navigator.userAgent,
    performance: window.performance,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
      colorDepth: window.screen.colorDepth,
      pixelDepth: window.screen.pixelDepth,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    },
    devicePixelRatio: window.devicePixelRatio
  }
}