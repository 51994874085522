import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { MessageTypeEnum, RecommendProps, PostEntity } from "../../models/post";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import { _loadState } from "../redux/app-slice";
import { LocationEntity } from "src/models/location";
import { RecommendTypeEnum } from "src/pages/main/recommend/RecommendMain";
import { TopicResponse } from "src/models/topic";

interface HotListResponse {
  data: LocationEntity[];
  startkey: string;
  type: string;
}

interface RecommendDataResponse {
  recommend_list: { data_info: any; data_type: RecommendTypeEnum }[];
}
export const homeApi = createApi({
  reducerPath: "home",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getRecommendList: builder.mutation<Result<any>, RecommendProps>({
        query: ({
          contentType,
          geoHash,
          pageSize,
          startKey,
          forwardOrder,
          sort_type,
          deviceId,
          id_list,
        }) => ({
          url: `getrecommendlist`,
          method: "POST",
          body: {
            content_type: contentType,
            geohash: geoHash,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            sort_type,
            id_list,
          },
          headers: {
            "Client-Device-Id": deviceId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getTypedRecommendData: builder.mutation<
        Result<RecommendDataResponse>,
        {}
      >({
        query: () => ({
          url: `gettoprecommendlist`,
          method: "POST",
          body: {},
        }),
        transformResponse: (res: Response<RecommendDataResponse>) =>
          new Value(res),
      }),

      // all post
      getAllPost: builder.mutation<
        Result<any>,
        {
          startKey?: string;
          admin_area: string;
        }
      >({
        query: ({ startKey, admin_area }) => ({
          url: `getallpostlist`,
          method: "POST",
          body: {
            page_size: 20,
            startkey: startKey,
            admin_area,
            content_type: "message",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getHotList: builder.mutation<
        Result<any>,
        {
          type: string;
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
        }
      >({
        query: ({ type, pageSize, startKey, forwardOrder }) => ({
          url: `gethotlist`,
          method: "POST",
          body: {
            type: type,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res, "res"),
      }),
      getWorldViewHotList: builder.query<
        Result<HotListResponse>,
        {
          type: string;
          pageSize?: number;
          startKey?: string;
          language: string;
          world_view: boolean;
          parent_loc_ids?: any[];
        }
      >({
        query: ({
          type,
          pageSize,
          startKey,
          language,
          world_view,
          parent_loc_ids,
        }) => ({
          url: `gethotlist`,
          method: "POST",
          body: {
            type: type,
            pagesize: pageSize || 20,
            startkey: startKey,
            language: language,
            world_view: world_view,
            parent_loc_ids,
          },
        }),
        transformResponse: (res: Response<HotListResponse>) =>
          new Value<HotListResponse>(res, "res"),
      }),
      getLocationList: builder.mutation<Result<any>, { locIds: string[] }>({
        query: ({ locIds }) => ({
          url: `getlocationlist`,
          method: "POST",
          body: {
            include_first_message: true,
            include_first_message_user: true,
            loc_ids: locIds,
            get_location_options: {
              language: _loadState().language,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      checkLocationAreaWithMessageID: builder.mutation<
        Result<any>,
        { messageID: string; level: number }
      >({
        query: ({ messageID, level }) => ({
          url: `location`,
          method: "POST",
          body: {
            request_type: "check_location_has_post",
            request_data: {
              message_id: messageID,
              location_number: level,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      checkLocationAreaWithLocName: builder.mutation<
        Result<any>,
        { locName: string }
      >({
        query: ({ locName }) => ({
          url: `location`,
          method: "POST",
          body: {
            request_type: "check_location_has_post",
            request_data: {
              loc_name: locName,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getLiveBroadcastList: builder.mutation<
        Result<any>,
        {
          gps: string;
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
        }
      >({
        query: ({ gps, pageSize, startKey, forwardOrder }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            gps: gps,
            message_type: 5,
            status: 3,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "get_message_list"),
      }),
      getLocationPostList: builder.mutation<
        Result<any>,
        {
          gps?: string;
          pageSize?: number;
          startKey?: string;
          forwardOrder?: boolean;
          area?: string;
          civil_level?: string;
          messageid?: string;
        }
      >({
        query: ({
          gps,
          pageSize,
          startKey,
          forwardOrder,
          area,
          civil_level,
          messageid,
        }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            civil_entity: area,
            gps: gps,
            message_type: 1,
            pagesize: pageSize || 20,
            startkey: startKey,
            forwardorder: forwardOrder,
            civil_level,
            messageid,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "get_message_list"),
      }),
      getTopicList: builder.mutation<
        Result<any>,
        { pagesize: number; topic_id: string; startkey: string }
      >({
        query: ({ pagesize, topic_id, startkey }) => ({
          url: `gettopiclist`,
          method: "POST",
          body: {
            forwardorder: false,
            pagesize,
            startkey,
            topic_id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getMessageDetail: builder.mutation<
        Result<any>,
        { messageId: string; messageType: MessageTypeEnum }
      >({
        query: ({ messageType, messageId }) => ({
          url: `getmessagebymessageid`,
          method: "POST",
          body: {
            message_type: messageType,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getMessageCommentList: builder.mutation<
        Result<any>,
        {
          messageId: string;
          messageType: MessageTypeEnum;
          pageSize?: number;
          startKey?: string;
        }
      >({
        query: ({ messageType, messageId, pageSize, startKey }) => ({
          url: `getmessage`,
          method: "POST",
          body: {
            action: "getmessage",
            // sequenceid: "aaaa",
            pagesize: pageSize,
            forwardorder: false,
            countonly: false,
            startkey: startKey,
            message_type: messageType,
            messageid: messageId,
          },
        }),
        transformResponse: (res: Response<any>) =>
          new Value(res, "get_message_list"),
      }),
      getTradeMarket: builder.mutation<
        Result<any>,
        {
          tradeType?: string;
          sortBy?: number;
          pageSize?: number;
          status?: number;
          forwardOrder?: boolean;
          startKey?: string;
          loc_id?: string;
          gps?: string;
          loc_name?: string;
        }
      >({
        query: ({
          tradeType,
          sortBy,
          pageSize,
          status,
          forwardOrder,
          startKey,
          loc_id,
          gps,
          loc_name,
        }) => ({
          url: `trade_market`,
          method: "POST",
          body: {
            request_type: "query_trade",
            request_data: {
              trade_type: tradeType,
              sort_by: sortBy,
              status: status,
              page_size: pageSize,
              forward_order: forwardOrder,
              start_key: startKey,
              loc_id,
              gps,
              loc_name,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      deleteComment: builder.mutation<
        Result<any>,
        {
          message_id: string;
        }
      >({
        query: ({ message_id }) => ({
          url: "deletemessage",
          method: "POST",
          body: {
            messageid: message_id,
            message_type: 1,
            action: "deletemessage",
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      popRecommend: builder.mutation<
        Result<undefined>,
        {
          id_list: string[];
          deviceId?: string;
        }
      >({
        query: ({ id_list, deviceId }) => ({
          url: "pop_recommend",
          method: "POST",
          body: {
            id_list,
          },
          headers: {
            "Client-Device-Id": deviceId,
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      getPostListByTag: builder.mutation<
        Result<TopicResponse>,
        {
          tagId: string;
          pageSize: number;
          startkey: string;
        }
      >({
        query: ({ tagId, pageSize, startkey }) => ({
          url: "tag",
          method: "POST",
          body: {
            type: "get_message_by_tag",
            input: {
              tag_id: tagId,
              page_size: pageSize,
              forward: false,
              start_key: startkey
            },
          },
        }),
        transformResponse: (res: Response<TopicResponse>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetRecommendListMutation,
  useGetTypedRecommendDataMutation,
  useGetAllPostMutation,
  useGetHotListMutation,
  useGetWorldViewHotListQuery,
  useGetLocationListMutation,
  useCheckLocationAreaWithLocNameMutation,
  useCheckLocationAreaWithMessageIDMutation,
  useGetLiveBroadcastListMutation,
  useGetLocationPostListMutation,
  useGetTopicListMutation,
  useGetMessageDetailMutation,
  useGetMessageCommentListMutation,
  useGetTradeMarketMutation,
  useLazyGetWorldViewHotListQuery,
  useDeleteCommentMutation,
  usePopRecommendMutation,
  useGetPostListByTagMutation,
} = homeApi;
