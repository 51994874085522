export const IOSAppUrlScheme = "anylocal://anylocal.com";


export const AndroidHrefStart =
  "intent://#Intent;scheme=anylocal;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;";

export const AndroidHrefEnd = "end";




