import { MessageTypeEnum, PostMessageContent, PostEntity } from "./post";
import { Travel } from "src/models/travel";

export enum NotificationTypeEnum {
  Post = "post",
  Request = "request",
  User = "user",
  Message = "message",
  Finance = "finance",
  Announcement = "announcement",
  Travel = "travel",
  Subscription = "subscription",
  GroupChat = "group_chat"
}
export interface NotificationEntity {
  notification_list: Array<any>;
  last_notification_time: {
    request: number;
    post: number;
    user: number;
    message: number;
    unread_message_num: number;
  };
  startkey: string;
}

export interface LastNotificationTime {
  unread_announcement_num: number;
  unread_finance_num: number;
  unread_message_num: number;
  unread_post_num: number;
  unread_request_num: number;
  unread_user_num: number;
  unread_location_chat: number;
  unread_travel_num: number;
  unread_group_chat_num: number;
  unread_subscription_num: number;
}

export enum MessageContentType {
  Text = "text",
  Image = "image",
  Video = "video",
  Audio = "audio",
  Post = "post",
  SharePost = "share_post",
  ShareLocation = "share_location",
  ShareRequest = "share_request",
  ShareUser = "share_user",
  ShareTopic = "share_topic",
  ShareRegion = "share_region",
}

export interface MessageNotification {
  dialog_id: number;
  unread_count: boolean;
  talk_with: {
    user_avatar: string;
    user_name: string;
    user_name_cn: string;
    user_id: string;
    type: string;
  };
  last_message_time: number;
  last_message: {
    content: string;
    id: string;
    type: MessageContentType;
    user_avatar: string;
    user_id: string;
    user_name: string;
    room_message_id?: string;
  };
}

export interface RoomInfo {
  message_id: string;
  room_id: string;
  name: string;
}

export interface BaseNotification {
  is_read: boolean;
  notification_id: string;
  notification_message: string;
  notification_message_cn: string;
  sub_type: string;
  timestamp: number;
  user: {
    user_avatar: string;
    user_name: string;
    user_id: string;
  };
  post_message?: PostMessageContent;
  origin_message?: PostEntity;
  associated_info_list?: RoomInfo[];
}

export interface FollowerNotification extends BaseNotification { }

export interface TravelNotification extends BaseNotification {
  message: Travel;
}

export interface SubscriptionNotification extends BaseNotification {
  subscribed_user:{
    user_id: string;
  }
}

export interface PostNotification extends BaseNotification {
  message?: {
    description: string;
    messageid: string;
  };
  message_type: MessageTypeEnum;
  type: string;
  associated_id: string;
}

export interface RequestNotification extends BaseNotification {
  message?: {
    description: string;
    paidInfo: {
      amount: number;
      currency: string;
      pay_amount: number;
      paymenttype: number;
      service_fee: number;
      token: string;
    };
  };
  message_type: MessageTypeEnum;
  type: string;
  associated_id: string;
}

export interface GroupChatNotification extends BaseNotification {
  message: {
    room_id: string;
    name: string;
    description: string;
    is_private: boolean;
    online: number;
    owner_id: string;
    room_cover: string;
  };
  associated_id: string;
  sub_type: GroupChatNotificationSubType
}

export enum GroupChatNotificationSubType {
  Agree_Join = 'agree_join',
  Apply_Join_Room = 'apply_join_room',
  Reject_Join = 'reject_join',
}