import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import IcoTop from "src/images/root/ico-top.svg";
const BackToTop = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return isVisible ? (
    <Box
      component="img"
      src={IcoTop}
      onClick={scrollToTop}
      style={{
        position: "fixed",
        bottom: isMobile ? "70px" : "20px",
        right: "20px",
        cursor: "pointer",
        zIndex: 1000,
        borderRadius: "50%",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
      }}
    />
  ) : null;
};

export default BackToTop;
