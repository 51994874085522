import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Box, Typography, Avatar, CircularProgress, IconButton, MenuItem, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSnackbar } from 'notistack';
import PreviewPicture from 'src/components/preview/PreviewPicture';
import PreviewVideo from 'src/components/preview/PreviewVideo';
import { getNumberDesc } from 'src/tools/utils/stringUtil';
import { ChatMessage, MessageStatusEnum } from 'src/models/chat';
import { PostEntity, getCoverImage } from 'src/models/post';
import { useSelector } from 'src/tools/toolkit/store';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from "src/images/defaultIcons/default-avatar.png";
import IcoCopy from "src/images/chatroom/ico-copy.svg";
import IcoDelete from "src/images/chatroom/ico-delete.svg";
import { appConfig } from "src/app-config";
import AudioPlayer from "src/components/audio/audioPlayer";
import { AudioPlayerProvider } from 'src/providers/AudioPlayerContext';
import IcoPointWhite from "src/images/chatroom/point-white.svg";
import IcoPoint2White from "src/images/chatroom/point2-white.svg";
import IcoPoint3White from "src/images/chatroom/point3-white.svg";
import IcoLocation from "src/images/post/ico-location-small-blue.svg";
import { LocationAreaEntity } from 'src/pages/location-area/LocationArea';
import { RequestEntity } from 'src/models/request';
import IcoRequest from "src/images/defaultIcons/ico-request.svg";
import IcoTag from "src/images/defaultIcons/ico-tag.svg";
import { calculatePriceStr } from 'src/tools/utils/postUtil';
import { ShareTopicEntity, ShareTagEntity, ShareTopicTag } from 'src/models/topic';
import {
  getPostLabel, handleClickLocationArea, getSharedLocationAreaName, getSharedUserName,
  locationData, getSharedLocationLinkName, getSharedLocationName, IsAI,
  requestData, getSharedRequestName, MessageType, getDisplayUserName, welcomeMessageID
} from 'src/tools/utils/chatUtil';
import { getLocationCoverImage } from 'src/models/location';
import { CommonUser } from 'src/models/user';
import { Base64 } from 'js-base64';
import { UserEntity, SearchMessageObject } from "src/models/chatroom";
import IcoMention from "src/images/chatroom/ico-@-16_16.svg";
import IcoProfile from "src/images/chatroom/ico-profile.svg";
import { IcoRobot } from "src/tools/utils/chatroom"
import IcoReply from "src/images/chatroom/ico-reply.svg";
import { WebsocketChatMessage } from "src/models/chat";
import TextMessage from './TextMessage';
import SharePostMessage from 'src/components/chat/SharePostMessage';
import AIPostBG from "src/images/chatroom/bg-posts.png";
import AIPostBGRound from "src/images/chatroom/ai-post-bg-round.png";
import IcoMultiImages from "src/images/chatroom/ico-multi-images.svg";
import PreviewAvatar from "src/components/preview/PreviewAvatar";
import IcoView from "src/images/chatroom/ico-view.svg"
import ProfileDialog from 'src/components/dialog/profile/ProfileDialog';
import { isCN } from 'src/tools/utils/appUtil';
import { isValidJSON } from "src/tools/utils/appUtil"

const ITEM_HEIGHT = 48;
const LoadingImages = [IcoPointWhite, IcoPoint2White, IcoPoint3White];

interface Props {
  chatMessageList: ChatMessage[];
  handleDelete: (id: string) => void;
  handleUserMention: (item: UserEntity) => void,
  onlineMembers: UserEntity[],
  handleReply: (row: ChatMessage) => void;
  searchMessageObject: SearchMessageObject | null
}

interface ActionProps {
  row: ChatMessage;
  handleDelete: (id: string) => void;
  handleReply: (row: ChatMessage) => void;

}

export const AIAnswerPostStyle = {
  background: 'unset',
  backgroundImage: `url(${AIPostBG})`,
  backgroundSize: "100% 100%",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: "center center",
  padding: '12px 24px 20px 12px',
  width: "270px",
  position: 'relative',
  "& .originMessageContainer": {
    WebkitLineClamp: 2,
  },
  "& .aiPostImage": {
    position: 'relative',
    cursor: "pointer",
  },
  "& .aiPostImage .postImage": {
    width: "234px",
    height: '234px',
    objectFit: 'cover',
    borderRadius: '0 0 32px 0',
    marginTop: '5px',
    position: 'relative'
  },
  "& .roundImg": {
    position: 'absolute',
    width: '77px',
    height: '82px',
    right: '8px',
    bottom: '6px',
    zIndex: 2,
  },
  // 为了防止高度变化是背景图片上面和下面部分拉伸变形，所以加了before和after
  "&:: before, &:: after": {
    content: '""',
    position: 'absolute',
    left: 0,
    width: '100%',
    backgroundImage: `url(${AIPostBG})`,
    backgroundRepeat: 'no-repeat',
    height: "300px"
  },
  "&::before": {
    top: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
  "&::after": {
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  }
}

const groupMessagesBy5Minutes = (messages: ChatMessage[]) => {
  const groupedMessages: { [key: string]: ChatMessage[] } = {};

  if (messages.length === 0) {
    return groupedMessages;
  }
  console.log('messages:', messages);

  let currentGroupKey = ''; // 当前分组的键
  let lastMessageTime = messages[0]?.time || 0; // 上一条消息的时间戳，默认为第一条消息的时间戳

  messages.forEach((message: ChatMessage) => {
    if (!message?.time) {
      return;
    }
    const messageTime = message.time;

    // 如果当前消息与上一条消息的时间差大于5分钟，更新分组键
    if (messageTime - lastMessageTime > 5 * 60) {
      // currentGroupKey = messageTime.toString(); // 使用当前消息的时间戳作为键
      currentGroupKey = new Date(messageTime * 1000).toLocaleString();
      // 更新上一条消息的时间戳
      lastMessageTime = messageTime;
    } else {
      currentGroupKey = new Date(lastMessageTime * 1000).toLocaleString();
    }

    if (!groupedMessages[currentGroupKey]) {
      groupedMessages[currentGroupKey] = [];
    }
    groupedMessages[currentGroupKey].push(message);
  });

  return groupedMessages;
}

const MoreAction: React.FC<ActionProps> = ({ row, handleDelete, handleReply }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { credential } = useSelector(state => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCopy = (row) => {
    navigator.clipboard.writeText(row.content);
    enqueueSnackbar('Copied', { variant: 'success' })
    handleClose();
  }

  const handleClickDelete = (row) => {
    handleDelete(row.id);
    handleClose();
  }

  const handleClickReply = (row) => {
    handleReply(row);
    handleClose();
  }

  const isShowMore = useMemo(() => {
    if ((row.type === MessageType.Image || row.type === MessageType.Text || row.type === MessageType.Video) && row.id !== welcomeMessageID) {
      return true;
    }
    if (row.type === MessageType.Audio || row.type === MessageType.Share_Post) {
      return false;
    }
    if ((credential?.is_admin || credential?.user_id === row.sender_id) && row.id !== welcomeMessageID) {
      return true;
    }
    return false;
  }, [row, credential?.is_admin, credential?.user_id]);

  if (!isShowMore) {
    return <></>;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ padding: '0', display: isShowMore ? "" : "none" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '148px',
          },
        }}
      >
        {
          row.type !== MessageType.Audio &&
          <MenuItem key="copy" onClick={() => handleClickCopy(row)} sx={{ height: ITEM_HEIGHT, display: 'flex', gap: '15px', fontSize: '14px', fontWeight: '400' }}>
            <img src={IcoCopy} width={16} height={16} />
            {t("app.chatroom.copy")}
          </MenuItem>
        }
        {
          (row.type === MessageType.Image || row.type === MessageType.Text || row.type === MessageType.Video) && row.id !== welcomeMessageID &&
          <MenuItem key="reply" onClick={() => handleClickReply(row)} sx={{ height: ITEM_HEIGHT, display: 'flex', gap: '15px', fontSize: '14px', fontWeight: '400' }}>
            <img src={IcoReply} width={16} height={16} />
            {t("app.chatroom.reply")}
          </MenuItem>
        }
        {(credential?.is_admin || credential?.user_id === row.sender_id) && row.id !== welcomeMessageID &&
          <MenuItem key="delete" onClick={() => handleClickDelete(row)} sx={{ height: ITEM_HEIGHT, display: 'flex', gap: '15px', fontSize: '14px', fontWeight: '400', color: 'red' }}>
            <img src={IcoDelete} width={16} height={16} />
            {t("app.chatroom.delete")}
          </MenuItem>
        }
      </Menu>
    </>
  )
}

export const ChatMessageList: React.FC<Props> = ({ chatMessageList, handleDelete, handleUserMention, onlineMembers, handleReply, searchMessageObject }) => {
  const { t, i18n } = useTranslation();
  const { credential, userLocalId, userLocalName } = useSelector(state => state.app);
  const [previewPictureConfig, setPreViewPictureConfig] = useState({ open: false, pictureUrl: "" });
  const [previewVideoConfig, setPreViewVideoConfig] = useState({ open: false, videoUrl: "" });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [clickedUser, setClickUser] = useState<UserEntity | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const aiPostContainerRef = useRef<HTMLElement | null>(null);
  const [avatarOpen, setAvatarOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);

  useEffect(() => {
    const backdrop = document.getElementsByClassName("MuiBackdrop-root")[0] as HTMLDivElement;
    if (backdrop) {
      backdrop?.click();
    }
    setAnchorEl(null);
  }, [chatMessageList])

  useEffect(() => {
    let timer = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % LoadingImages.length);
    }, 300);
    return () => {
      clearInterval(timer);
    }
  }, [chatMessageList])

  const isUnknownType = (row: ChatMessage) => {
    return row.type !== MessageType.Text
      && row.type !== MessageType.Image
      && row.type !== MessageType.Video
      && row.type !== "post"
      && row.type !== MessageType.Audio
      && row.type !== MessageType.AI_Answer
      && row.type !== MessageType.Share_Post
      && row.type !== "share_location"
      && row.type !== "share_user"
      && row.type !== "share_region"
      && row.type !== "share_request"
      // && row.type !== "share_tag" // 没有share_tag这个类型，tag和topic都用的share_topic
      && row.type !== "share_topic";
  }

  const groupedMessages = useMemo(() => {
    return groupMessagesBy5Minutes(chatMessageList);
  }, [chatMessageList])

  const previewPicture = (url: string) => {
    setPreViewPictureConfig({
      open: true,
      pictureUrl: url,
    })
  }

  const previewVideo = (url: string) => {
    setPreViewVideoConfig({
      open: true,
      videoUrl: url,
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClickUser = (e, item: UserEntity) => {
    if (item?.user_id === userLocalId && (!credential || item?.type === "Visitor")) {
      return;
    }
    setAnchorEl(e.currentTarget);
    setClickUser(item);
  }

  const handleListItemClick = (item: UserEntity) => {
    setAnchorEl(null);
    handleUserMention(item);
  }

  const handleGoToProfile = (id: string) => {
    setAnchorEl(null);
    if (IsAI(clickedUser)) {
      window.open(`${appConfig.paths.aiProfile}/${id}`, "_blank");
    } else {
      // const isInternational = (isCN && id.endsWith('_com')) || (!isCN && id.endsWith('_cn'));
      // if (isInternational) {
      //   // // 外区用户，不跳转，仅预览
      //   // setProfileOpen(true);
      //   // 外区用户，打开新标签页
      //   if (id.endsWith('_com')){
      //     const cleanId = id.slice(0, -4)
      //     window.open(`${process.env.REACT_APP_WEBSITE_URL_COM}${appConfig.paths.user}?id=${cleanId}`, "_blank");
      //   }
      //   if (id.endsWith('_cn')) {
      //     const cleanId = id.slice(0, -3);
      //     window.open(`${process.env.REACT_APP_WEBSITE_URL_CN}${appConfig.paths.user}?id=${cleanId}`, "_blank");
      //   }
      // } else {
      //   // 本区用户，跳转到用户主页
      //   window.open(`${appConfig.paths.user}?id=${id}`, "_blank");
      // }
      window.open(`${appConfig.paths.user}?id=${id}`, "_blank");
    }
  }

  const handleViewAvatar = () => {
    setAnchorEl(null);
    setAvatarOpen(true);
  }

  const isOnline = (clickedUser) => {
    const foundUser = onlineMembers.find(memeber => memeber.user_id === clickedUser?.user_id);
    return foundUser;
  }

  const isAIAnswerPost = (row: ChatMessage) => {
    return row.type === MessageType.AI_Answer && row.post_list && row.post_list.length > 0;
  }

  const handleGoToPostList = (row: ChatMessage) => {
    window.open(`${appConfig.paths.aiPostList}?id=${row.id}`, "_blank");
  }

  return (
    <AudioPlayerProvider>
      <Box>
        {groupedMessages && Object.entries(groupedMessages).map(([timeGroup, messagesInGroup]) => (
          <Box key={timeGroup}>
            <Box
              sx={{
                margin: "10px 0",
                color: "#999999",
                fontSize: "12px",
                fontWeight: '400',
                textAlign: "center"
              }}
            >
              {timeGroup}
            </Box>
            {Array.isArray(messagesInGroup) && messagesInGroup.map((row, index) => (
              <Box
                key={row.id}
                sx={{
                  marginTop: "20px",
                  background: row?.id === searchMessageObject?.messageId ? "#F5F9FF" : "",
                  animation: row?.id === searchMessageObject?.messageId
                    ? "fadeOut 5s ease-out forwards"
                    : "none"
                }}
                id={`message-${row?.id}`}
              >
                {
                  <>
                    {row.sender_id === credential?.user_id || row?.sender_id === userLocalId ?
                      // 自己的消息
                      <Box sx={{
                        textAlign: "end"
                      }}>
                        {/* 自己的用户名和头像 */}
                        <Box sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "fit-content",
                          marginLeft: "auto",
                          "&:hover .more-action": {
                            opacity: "1",
                          },
                        }}>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: "fit-content",
                            marginLeft: "auto",
                          }}>
                            <Typography sx={{
                              textAlign: "right",
                              paddingBottom: '5px',
                              fontSize: "12px !important",
                              fontWeight: '400',
                              color: '#666666'
                            }} >
                              {getDisplayUserName(row, i18n, userLocalName)}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Box className="more-action" sx={{ padding: "3px 0 0 0", opacity: '0' }}>
                                <MoreAction handleDelete={handleDelete} row={row} handleReply={handleReply} />
                              </Box>
                              {row.type === MessageType.Text ?
                                <TextMessage
                                  row={row}
                                  previewPicture={previewPicture}
                                  previewVideo={previewVideo}
                                  isOwn={true}
                                />
                                :
                                <Box sx={{
                                  marginLeft: "auto",
                                  background: "rgba(217, 239, 255, 1)",
                                  borderRadius: "10px 0 10px 10px",
                                  maxWidth: "350px",
                                  padding: "7px",
                                }}>
                                  {row.type === MessageType.Image &&
                                    <Box sx={{ position: 'relative' }}>
                                      <Avatar
                                        sx={{
                                          width: "96px !important",
                                          height: "96px !important",
                                          cursor: "pointer",
                                        }}
                                        variant="square"
                                        src={row.content}
                                        onClick={() => previewPicture(row.content)}
                                      />
                                      {row.status === MessageStatusEnum.Sending &&
                                        <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <CircularProgress sx={{ width: '40px !important', height: '40px !important' }} />
                                        </Box>
                                      }
                                    </Box>

                                  }
                                  {row.type === MessageType.Video &&
                                    <Box sx={{ position: 'relative' }}>
                                      <video
                                        src={row.content}
                                        loop
                                        muted
                                        controls
                                        playsInline
                                        poster={row.thumbnail}
                                        style={{ width: '96px', height: '96px', objectFit: "cover", cursor: "pointer", }}
                                        onClick={() => previewVideo(row.content)}
                                      ></video>
                                      {row.status === MessageStatusEnum.Sending &&
                                        <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.7)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <CircularProgress sx={{ width: '40px !important', height: '40px !important' }} />
                                        </Box>
                                      }
                                    </Box>
                                  }
                                  {row.type === MessageType.Audio &&
                                    <>
                                      {
                                        row.status === MessageStatusEnum.Sending ?
                                          <img src={LoadingImages[currentImageIndex]} />
                                          : <AudioPlayer item={row} isOwn={true} />
                                      }
                                    </>
                                  }

                                  {row.type === "post" && isValidJSON(row.content) && row.content &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestPurchasePost}?id=${(JSON.parse(row.content) as PostEntity).messageid}`, "_blank")}>
                                      <img alt="post_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getCoverImage(JSON.parse(row.content) as PostEntity)} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getPostLabel(row, t)}</Box>
                                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                          <img alt="ico-location" src={IcoLocation} style={{ width: "12px", height: "14px", marginRight: "5px" }} />
                                          <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px" }}>{(JSON.parse(row.content) as PostEntity)?.loc_name}</Box>
                                        </Box>
                                        {JSON.parse(row.content)?.request_amount &&
                                          <Typography sx={{
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color: "#FF0000",
                                            marginTop: "6px"
                                          }}>
                                            {JSON.parse(row.content)?.request_amount} {JSON.parse(row.content)?.request_amount === 1 ? t("app.chat.coins") : t("app.chat.coins")}
                                          </Typography>}
                                      </Box>
                                    </Box>
                                  }
                                  {row.type === MessageType.Share_Post && row.content &&
                                    <SharePostMessage row={row} isOwn={true} />
                                  }

                                  {row.type === "share_location" && row.content &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.location}?loc_id=${locationData(row).loc_id}&loc_name=${Base64.encodeURI(getSharedLocationLinkName(row))}`, "_blank")}>
                                      <img alt="location_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={getLocationCoverImage(locationData(row))} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationName(row)}</Box>
                                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                          <Box sx={{ fontSize: "12px" }}>
                                            {`${getNumberDesc(locationData(row)?.total_visit_count || 0)} ${locationData(row)?.total_visit_count > 1 ? t("app.location.views") : t("app.location.view")}`}
                                          </Box>&nbsp;&nbsp;&nbsp;&nbsp;
                                          <Box sx={{ fontSize: "12px" }}>
                                            {`${getNumberDesc(locationData(row)?.total_message_count || 0)} ${locationData(row)?.total_message_count > 1 ? t("app.location.posts") : t("app.location.post")}`}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  }

                                  {row.type === "share_request" && row.content &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.requestDetail}?id=${(JSON.parse(row.content) as RequestEntity).messageid}`, "_blank")}>
                                      <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoRequest} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedRequestName(row)}</Box>
                                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                          <Box sx={{ fontSize: "12px" }}>{calculatePriceStr(Number(requestData(row).cost), requestData(row).message_content.paidInfo.currency, t)}</Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  }

                                  {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'topic' &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.topic}/${encodeURIComponent((JSON.parse(row.content) as ShareTopicEntity).topic_id)}`, "_blank")}>
                                      <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>#{(JSON.parse(row.content) as ShareTopicEntity).topic_id}</Box>
                                      </Box>
                                    </Box>
                                  }

                                  {row.type === "share_topic" && row.content && (JSON.parse(row.content) as ShareTopicTag).type === 'tag' &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.autoTopic}/${encodeURIComponent((JSON.parse(row.content) as ShareTagEntity).topic_id)}`, "_blank")}>
                                      <img alt="request_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={IcoTag} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>
                                          #{i18n.language === 'zh-CN' ? (JSON.parse(row.content) as ShareTagEntity).cn_name : (JSON.parse(row.content) as ShareTagEntity).name}
                                        </Box>
                                      </Box>
                                    </Box>
                                  }

                                  {row.type === "share_region" && row.content &&
                                    <Box style={{ padding: "5px 0", cursor: "pointer", minWidth: "100px" }}
                                      onClick={() => handleClickLocationArea(row)}>
                                      <Box sx={{ display: "flex", alignItems: "center", }}>
                                        <img src={IcoLocation} alt='location' style={{ marginRight: "5px" }} />
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedLocationAreaName(row)}</Box>
                                      </Box>
                                      <Box sx={{ fontSize: "12px", marginTop: "10px", textAlign: "left" }}>{`${getNumberDesc((JSON.parse(row.content) as LocationAreaEntity)?.post_count || 0)} ${t("app.location.posts")}`}</Box>
                                    </Box>
                                  }

                                  {row.type === "share_user" && row.content &&
                                    <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.user}?id=${(JSON.parse(row.content) as CommonUser).user_id}`, "_blank")}>
                                      <Avatar alt="user_image" style={{ width: "64px", height: "64px", minWidth: "64px", objectFit: "cover" }} src={(JSON.parse(row.content) as CommonUser).user_avatar || DefaultAvatar} />
                                      <Box sx={{ marginLeft: "10px", textAlign: "left" }}>
                                        <Box sx={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 600 }}>{getSharedUserName(row)}</Box>
                                        <Box sx={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px", marginTop: "10px", }}>{(JSON.parse(row.content) as CommonUser).personalized_signature}</Box>
                                      </Box>
                                    </Box>
                                  }

                                  {isUnknownType(row) &&
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                      <AttachFileIcon fontSize='small' style={{ color: "blue" }} />
                                      <Typography sx={{
                                        color: "#666666",
                                        fontSize: "14px !important",
                                        textAlign: "left",
                                        wordBreak: "break-word",
                                      }}>{row.content}</Typography>
                                    </Box>
                                  }

                                </Box>
                              }
                            </Box>

                          </Box>
                          <Avatar
                            onClick={(e) => handleClickUser(e, { user_id: row.sender_id, user_name: row.sender_name, user_avatar: row.sender_avatar, user_name_cn: row.sender_name_cn, type: row.sender_type })}
                            src={row.sender_avatar || DefaultAvatar}
                            style={{ width: '40px', height: '40px', marginLeft: '5px', cursor: 'pointer' }}
                          />
                        </Box>
                      </Box>
                      :
                      // 别人的消息
                      <Box sx={{
                        textAlign: "start"
                      }}>
                        {/* 别人的头像和用户名 */}
                        <Box sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "fit-content",
                          gap: '5px',
                        }}>
                          <Avatar
                            onClick={(e) => handleClickUser(e, { user_id: row.sender_id, user_name: row.sender_name, user_avatar: row.sender_avatar, user_name_cn: row.sender_name_cn, type: row.sender_type })}
                            // The robot's avatar is directly obtained from local for faster loading speed.
                            src={IsAI({ type: row.sender_type }) ? row?.sender_avatar || IcoRobot : row.sender_avatar || DefaultAvatar}
                            style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                          />
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: "fit-content",
                            marginLeft: "auto",
                          }}>
                            <Box sx={{ display: 'flex', gap: '15px', paddingBottom: '5px' }}>
                              <Typography sx={{
                                textAlign: "left",
                                fontSize: "12px !important",
                                fontWeight: '400',
                                color: '#666666'
                              }}>
                                {getDisplayUserName(row, i18n)}
                              </Typography>
                              {
                                row.is_local &&
                                <Box
                                  sx={{
                                    color: '#3A92F9',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    borderRadius: '5px',
                                    backgroundColor: '#3A92F933',
                                    padding: '0 5px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {t("app.chatroom.local")}
                                </Box>
                              }
                              {/* <Typography sx={{
                        textAlign: "left",
                        paddingBottom: '5px',
                        fontSize: "12px !important",
                        fontWeight: '400',
                        color: '#666666',
                      }} >
                        IP: Beijing
                      </Typography> */}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                "&:hover .more-action": {
                                  opacity: "1",
                                },
                              }}
                            >
                              {row.type === MessageType.Text ?
                                <TextMessage
                                  row={row}
                                  previewPicture={previewPicture}
                                  previewVideo={previewVideo}
                                  isOwn={false}
                                />
                                :
                                <Box
                                  ref={aiPostContainerRef}
                                  sx={{
                                    background: "#E9EDF2",
                                    borderRadius: "0 10px 10px 10px",
                                    maxWidth: "350px",
                                    padding: "10px",
                                    ...(isAIAnswerPost(row) ? AIAnswerPostStyle : {})
                                  }}>
                                  {row.type === MessageType.Image &&
                                    <Avatar
                                      sx={{
                                        width: "96px !important",
                                        height: "96px !important",
                                        cursor: "pointer",
                                      }}
                                      variant="square"
                                      src={row.content}
                                      onClick={() => previewPicture(row.content)}
                                    />
                                  }
                                  {row.type === MessageType.Video &&
                                    <video
                                      src={row.content}
                                      loop
                                      muted
                                      controls
                                      playsInline
                                      poster={row.thumbnail}
                                      style={{ width: '96px', height: '96px', objectFit: "cover", cursor: "pointer", }}
                                      onClick={() => previewVideo(row.content)}
                                    ></video>
                                  }
                                  {row.type === MessageType.Audio &&
                                    <AudioPlayer item={row} isOwn={false} />
                                  }
                                  {row.type === MessageType.AI_Answer &&
                                    <>
                                      {isAIAnswerPost(row) && <img src={AIPostBGRound} className='roundImg' />}
                                      <Box sx={{
                                        lineHeight: '18px', fontFamily: "SF Pro Text",
                                        fontSize: '14px', fontWeight: '400',
                                        wordBreak: "break-word",
                                        whiteSpace: "pre-line",
                                        position: 'relative',
                                        zIndex: 1
                                      }}>
                                        <Box
                                          sx={{
                                            color: "#666666",
                                            fontStyle: 'italic',
                                            WebkitLineClamp: 5,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            '-webkit-box-orient': 'vertical',
                                            paddingRight: '3px'
                                          }}
                                          className="originMessageContainer"
                                        >
                                          {getDisplayUserName((row.origin_message as WebsocketChatMessage)?.message, i18n)}: {(row.origin_message as WebsocketChatMessage)?.message.content}
                                        </Box>
                                        <Box sx={{ width: "100%", borderBottom: "1px solid #D7DCE3", margin: "10px 0" }}></Box>
                                        <Box sx={{ color: "#000" }}>
                                          {row.content}
                                          {isAIAnswerPost(row) &&
                                            <Box className="aiPostImage" onClick={() => handleGoToPostList(row)}>
                                              <img src={IcoMultiImages} style={{ position: 'absolute', width: "24px", height: '24px', zIndex: 2, top: '10px', left: '3px' }} />
                                              <img className="postImage" src={row?.post_list && row?.post_list[0]} />
                                            </Box>
                                          }
                                        </Box>
                                      </Box>
                                    </>

                                  }
                                  {row.type === MessageType.Share_Post && row.content &&
                                    <SharePostMessage row={row} isOwn={false} />
                                  }
                                </Box>
                              }
                              <Box className="more-action" sx={{ padding: "3px 0 0 0", opacity: '0' }}>
                                <MoreAction handleDelete={handleDelete} handleReply={handleReply} row={row} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    }
                  </>
                }

              </Box>
            ))
            }
          </Box>
        ))}

        {/* no message layout */}
        {/* <Box style={{ textAlign: 'center', marginTop: "30px" }}>
          <SvgComponent iconName='ico-no-chat' style={{ width: '48px', height: '48px' }} />
          <Typography style={{ textAlign: "center", color: "#999999", marginTop: "10px" }}>{initing ? t("app.user.loading") : t("app.chat.noMessage")}</Typography>
        </Box> */}

        <PreviewPicture
          showDialog={previewPictureConfig.open}
          pictureUrl={previewPictureConfig.pictureUrl}
          onClose={() => setPreViewPictureConfig({ open: false, pictureUrl: previewPictureConfig.pictureUrl })} />

        <PreviewVideo
          showDialog={previewVideoConfig.open}
          videoUrl={previewVideoConfig.videoUrl}
          onClose={() => setPreViewVideoConfig({ open: false, videoUrl: previewVideoConfig.videoUrl })} />

        {avatarOpen &&
          <PreviewAvatar
            open={avatarOpen}
            user={clickedUser}
            handleClose={() => setAvatarOpen(false)}
          />
        }

        {open &&
          <Menu
            onClose={handleClose}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                width: '180px',
                borderRadius: '8px',
              },
            }}
          >
            {credential?.user_id != clickedUser?.user_id &&
              < MenuItem
                sx={{ display: "flex", alignItems: 'center', gap: "10px", cursor: "pointer", fontSize: '14px', fontWeight: '400', lineHeight: '48px', padding: '0 16px' }}
                onClick={() => clickedUser && handleListItemClick(clickedUser)}
              >
                <img src={IcoMention} alt="@" width="16" height="16" />
                <Box
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: "Helvetica Neue",
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                >
                  {getDisplayUserName(clickedUser, i18n)}
                </Box>
              </MenuItem>
            }

            {/* {!IsAI(clickedUser) && */}
            <MenuItem
              sx={{ display: "flex", alignItems: 'center', gap: "10px", cursor: "pointer", fontSize: '14px', fontWeight: '400', lineHeight: '48px', padding: '0 16px' }}
              onClick={() => clickedUser?.user_id && handleGoToProfile(clickedUser?.user_id)}
            >
              <img src={IcoProfile} alt="profile" width="16" height="16" />
              {t("app.chatroom.profile")}
            </MenuItem>
            {/* } */}

            {clickedUser?.user_avatar && <MenuItem
              sx={{ display: "flex", alignItems: 'center', gap: "10px", cursor: "pointer", fontSize: '14px', fontWeight: '400', lineHeight: '48px', padding: '0 16px' }}
              onClick={handleViewAvatar}
            >
              <img src={IcoView} alt="IcoView" width="16" height="16" />
              {t("app.chatroom.view_avatar")}
            </MenuItem>}
          </Menu>
        }
        {profileOpen && <ProfileDialog
          open={profileOpen}
          user={clickedUser}
          onClickClose={() => setProfileOpen(false)}
        />}

      </Box>
    </AudioPlayerProvider >
  )
}