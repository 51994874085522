import { Badge, Box, Divider, Tab, Tabs, useTheme, CircularProgress } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { styled } from '@mui/system'
import { useReadNotificationMutation, useReadNotificationTypeMutation, useGetNotificationListMutation } from '../../service/api/notification-api';
import { LastNotificationTime, NotificationTypeEnum } from "../../models/notification";
import { enqueueSnackbar } from "notistack";
import { TabPanel } from "src/components/tab/tab";
import { useTranslation } from "react-i18next";
import MessagesTab from "./tabContent/MessagesTab";
import FollowersTab from "./tabContent/FollowersTab";
import PostsTab from "./tabContent/PostsTab";
import RequestsTab from "./tabContent/RequestsTab";
import RewardTab from "./tabContent/RewardTab";
import NewsTab from "./tabContent/NewsTab";
import TravelsTab from "./tabContent/TravelsTab"
import GroupChatTab from "./tabContent/GroupChatTab";
import SubscriptionTab from "./tabContent/SubscriptionTab";

interface Props {
    lastNotificationTimeList1: LastNotificationTime,
    updateRead: (read: boolean) => void,
}

const NotificationTab = styled(Tab)({
    color: '#717171',
    background: 'rgba(246, 246, 246, 1)',
    borderRadius: '16px',
    fontSize: '12px !important',
    fontWeight: '400',
    padding: '0 8px',
    minHeight: '28px',
    textTransform: 'none',
    fontFamily: 'Helvetica Neue !important',
    minWidth: "60px",
    '&.Mui-selected': {
        color: 'rgba(58, 146, 249, 1)',
        fontWeight: '500',
        backgroundColor: '#3A92F933'
    }
})


const TabValue = {
    messages: 0,
    followers: 1,
    posts: 2,
    requests: 3,
    reward: 4,
    news: 5,
    travel: 6,
    subscription: 7,
    groupChat: 8
}

const NotificationBox: FC<Props> = ({ lastNotificationTimeList1, updateRead }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isChineseLanguage = i18n.language === 'zh-CN';
    const [tabValue, setTabValue] = useState(TabValue.messages);
    const [isMessagesRead, setIsMessagesRead] = useState(lastNotificationTimeList1.unread_message_num <= 0);
    const [isFollowersRead, setIsFollowersRead] = useState(true);
    const [isPostsRead, setIsPostsRead] = useState(true);
    const [isRequestsRead, setIsRequestsRead] = useState(true);
    const [isRewardRead, setIsRewardRead] = useState(true);
    const [isAnnouncementRead, setIsAnnouncementRead] = useState(true);
    const [isTravelRead, setIsTravelRead] = useState(true);
    const [isGroupChatRead, setIsGroupChatRead] = useState<boolean>(true);
    const [isSubscriptionRead, setIsSubscriptionRead] = useState(true);
    const [lastNotificationTimeList, setLastNotificationTimeList] = useState<LastNotificationTime>()

    const [readNotificationType, { isLoading: updateTypeLoading }] = useReadNotificationTypeMutation();
    const [readNotification, { isLoading: updateLoading }] = useReadNotificationMutation();
    const [getNotificationList, { isLoading }] = useGetNotificationListMutation();

    const [loading, setLoading] = useState<boolean>(false);

    const hasNewNotification = useCallback(() => {
        getNotificationList({}).unwrap().then(res => {
            if (res && !res.isFailed && res.result) {
                setLastNotificationTimeList(res.result.last_notification_time);
            }
            else {
                enqueueSnackbar('Failed to get notification list')
            }
        })
    }, [getNotificationList])

    // useEffect(() => {
    //     setLastNotificationTimeList(lastNotificationTimeList1)
    // }, [lastNotificationTimeList1]);

    useEffect(() => {
        hasNewNotification();
    }, [hasNewNotification])

    useEffect(() => {
        if (lastNotificationTimeList) {
            const { unread_request_num, unread_post_num, unread_user_num, unread_message_num, unread_finance_num, unread_announcement_num, unread_travel_num, unread_group_chat_num, unread_subscription_num } = lastNotificationTimeList;
            const messageRead = (unread_message_num ?? 0) <= 0;
            setIsMessagesRead(messageRead);
            const userRead = (unread_user_num ?? 0) <= 0;
            setIsFollowersRead(userRead);
            const postRead = (unread_post_num ?? 0) <= 0;
            setIsPostsRead(postRead);
            const requestRead = (unread_request_num ?? 0) <= 0;
            setIsRequestsRead(requestRead);
            const rewardRead = (unread_finance_num ?? 0) <= 0;
            setIsRewardRead(rewardRead);
            const announcementRead = (unread_announcement_num ?? 0) <= 0;
            setIsAnnouncementRead(announcementRead);
            const travelRead = (unread_travel_num ?? 0) <= 0;
            setIsTravelRead(travelRead)
            const groupChatRead = (unread_group_chat_num ?? 0) <= 0;
            setIsGroupChatRead(groupChatRead);
            updateRead(messageRead && userRead && postRead && requestRead && announcementRead && travelRead && groupChatRead);
            const subscriptionRead = (unread_subscription_num ?? 0) <= 0;
            setIsSubscriptionRead(subscriptionRead);
            updateRead(messageRead && userRead && postRead && requestRead && announcementRead && travelRead && subscriptionRead);
        }
    }, [lastNotificationTimeList, updateRead])

    useEffect(() => {
        updateRead(isMessagesRead && isFollowersRead && isPostsRead && isRequestsRead && isAnnouncementRead && isTravelRead && isSubscriptionRead);
    }, [updateRead, isFollowersRead, isMessagesRead, isPostsRead, isRequestsRead, isAnnouncementRead, isTravelRead, isSubscriptionRead])

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case TabValue.messages:
                setIsMessagesRead(true);
                handleReadNotificationType(NotificationTypeEnum.Message);
                break;
            case TabValue.requests:
                setIsRequestsRead(true);
                handleReadNotificationType(NotificationTypeEnum.Request);
                break;
            case TabValue.followers:
                setIsFollowersRead(true);
                handleReadNotificationType(NotificationTypeEnum.User);
                break;
            case TabValue.posts:
                setIsPostsRead(true);
                handleReadNotificationType(NotificationTypeEnum.Post);
                break;
            case TabValue.reward:
                setIsRewardRead(true);
                handleReadNotificationType(NotificationTypeEnum.Finance);
                break;
            case TabValue.news:
                setIsAnnouncementRead(true);
                handleReadNotificationType(NotificationTypeEnum.Announcement);
                break;
            case TabValue.travel:
                setIsTravelRead(true);
                handleReadNotificationType(NotificationTypeEnum.Travel);
                break;
            case TabValue.groupChat:
                setIsGroupChatRead(true);
                handleReadNotificationType(NotificationTypeEnum.GroupChat);
                break;
            case TabValue.subscription:
                setIsSubscriptionRead(true);
                handleReadNotificationType(NotificationTypeEnum.Subscription);
                break;
            default:
                break;
        }
    };

    const handleReadNotificationType = (notificationType: NotificationTypeEnum) => {
        readNotificationType({ notificationType }).unwrap().then(res => {
            if (res && !res.isFailed) {
                // enqueueSnackbar('Read notification successfully',{variant:'success'})
            } else {
                enqueueSnackbar('Failed to read notification')
            }
        })
    }

    const handleReadNotification = useCallback((notificationID: string) => {
        readNotification({ notificationId: notificationID }).unwrap().then(res => {
            if (res && !res.isFailed) {
                // enqueueSnackbar('Read notification successfully',{variant:'success'})
            } else {
                enqueueSnackbar('Failed to read notification')
            }
        })
    }, [])

    return (
        <Box sx={{
            position: "relative"
        }}>
            {loading && <Box sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pointerEvents: "auto !important",
                zIndex: 2000,
                backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}>
                <CircularProgress />
            </Box>}
            <Box
                sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    padding: '8px 20px 16px 20px',
                    fontFamily: 'Helvetica Neue',
                }}
            >
                {t("app.header.notification")}
            </Box>
            <Divider></Divider>
            <Tabs value={tabValue}
                onChange={handleChangeTab}
                aria-label="icon tabs example"
                sx={{
                    padding: "10px 20px",
                    minHeight: 'unset',
                    maxWidth: '410px',
                    ".MuiTabs-indicator": {
                        display: "none",
                    },
                    "& .MuiTabs-flexContainer": {
                        gap: '10px',
                        flexWrap: 'wrap'
                    }
                }}
            >
                <NotificationTab aria-label="messages"
                    icon={
                        <Badge color="error" variant="dot" invisible={isMessagesRead}>
                            {t("app.header.messages")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="followers"
                    icon={
                        <Badge color="error" variant="dot" invisible={isFollowersRead}>
                            {t("app.header.followers")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="posts"
                    icon={
                        <Badge color="error" variant="dot" invisible={isPostsRead}>
                            {t("app.header.posts")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="requests"
                    icon={
                        <Badge color="error" variant="dot" invisible={isRequestsRead}>
                            {t("app.header.requests")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="reward"
                    icon={
                        <Badge color="error" variant="dot" invisible={isRewardRead}>
                            {t("app.header.reward")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="news"
                    icon={
                        <Badge color="error" variant="dot" invisible={isAnnouncementRead}>
                            {t("app.header.news")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="travel"
                    icon={
                        <Badge color="error" variant="dot" invisible={isTravelRead}>
                            {t("app.header.travel")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="subscription"
                    icon={
                        <Badge color="error" variant="dot" invisible={isSubscriptionRead}>
                            {t("app.header.subscription")}
                        </Badge>
                    }
                />
                <NotificationTab aria-label="groupChat"
                    icon={
                        <Badge color="error" variant="dot" invisible={isGroupChatRead}>
                            {t("app.header.groupChat")}
                        </Badge>
                    }
                />
            </Tabs>
            <Divider></Divider>
            <Box sx={{ width: "410px", [theme.breakpoints.down("sm")]: { width: "100%" } }}>
                <TabPanel value={tabValue} index={TabValue.messages}>
                    <MessagesTab
                        handleReadNotification={handleReadNotification}
                        hasNewNotification={hasNewNotification}
                        handleIsLoading={isLoading => setLoading(isLoading)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={TabValue.followers}>
                    <FollowersTab handleReadNotification={handleReadNotification} isChineseLanguage={isChineseLanguage} />
                </TabPanel>
                <TabPanel value={tabValue} index={TabValue.posts}>
                    <PostsTab
                        handleReadNotification={handleReadNotification}
                        isChineseLanguage={isChineseLanguage}
                        handleIsLoading={isLoading => setLoading(isLoading)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={TabValue.requests}>
                    <RequestsTab handleReadNotification={handleReadNotification} isChineseLanguage={isChineseLanguage} />
                </TabPanel>

                <TabPanel value={tabValue} index={TabValue.reward}>
                    <RewardTab handleReadNotification={handleReadNotification} isChineseLanguage={isChineseLanguage} />
                </TabPanel>

                <TabPanel value={tabValue} index={TabValue.news}>
                    <NewsTab />
                </TabPanel>

                <TabPanel value={tabValue} index={TabValue.travel}>
                    <TravelsTab
                        handleReadNotification={handleReadNotification}
                        isChineseLanguage={isChineseLanguage}
                        handleIsLoading={isLoading => setLoading(isLoading)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={TabValue.subscription}>
                    <SubscriptionTab
                        handleReadNotification={handleReadNotification}
                        isChineseLanguage={isChineseLanguage}
                        handleIsLoading={isLoading => setLoading(isLoading)}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={TabValue.groupChat}>
                    <GroupChatTab
                        handleReadNotification={handleReadNotification}
                        isChineseLanguage={isChineseLanguage}
                        handleIsLoading={isLoading => setLoading(isLoading)}
                    />
                </TabPanel>
            </Box>
        </Box>
    )
}
export default NotificationBox;