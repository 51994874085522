import { Avatar, Badge, Box, ListItem, ListItemAvatar ,Typography } from "@mui/material";
import React,{ FC, useMemo, useEffect, useCallback } from "react";
import { BaseNotification, RoomInfo } from "src/models/notification";
import { howLongAgo } from "src/tools/utils/stringUtil";
import DefaultPicture from '../../images/defaultIcons/default-pic-post.png'
import { getCoverImageFromPostContent } from "src/models/post";
import DefaultAvatar from "src/images/defaultIcons/default-avatar.png"
import { useTranslation } from "react-i18next";
import { appConfig } from "src/app-config";
import IcoDelete from "src/images/header/ico-delete.svg";
import { useDeleteNotificationMutation } from "src/service/api/notification-api";
import {
    useGetRelevantMessageMutation,
} from "src/service/api/chatroom-api";
import { useNavigate } from "react-router-dom";

interface Props {
    notificationInfo: BaseNotification;
    handleClick: () => void;
    isChineseLanguage: boolean;
    reloadList?: () => void;
    handleIsLoading?: (isLoading: boolean) => void;
}

const NotificationListItem: FC<Props> = ({ notificationInfo, handleClick, isChineseLanguage, reloadList, handleIsLoading }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [deleteNotification] = useDeleteNotificationMutation();
    const [getRelevantMessage, { isLoading: getLoading }] = useGetRelevantMessageMutation();

    

    const deleteNotificationFun = async (sub_type: string, timestamp: number) => {
        const params = {
            sub_type,
            timestamp
        }
        const res = await deleteNotification(params).unwrap();
    }

    const handleDeleteNotification = async (e, item) => {
        e.stopPropagation();
        await deleteNotificationFun(notificationInfo.sub_type, notificationInfo.timestamp);
        reloadList && reloadList();
    }

    const handleClickItem = () => {
        handleClick();
        notificationInfo.is_read = true;
    }

    const handleGetRelevantMessage = useCallback((item: RoomInfo) => {
        const param = {
            room_id: item?.room_id,
            message_id: item?.message_id,
            page_size: 30,
            type: "context",
        };
        
        getRelevantMessage(param)
            .unwrap()
            .then((res) => {
                const result = res?.result;
                if (!res?.isFailed) {
                    if (result?.itemList) {
                        handleIsLoading && handleIsLoading(false);
                        const extendedResult = {
                            ...result,
                            searchValue: "",
                            messageId: item?.message_id,
                            dialogId: item?.message_id
                        };
                        navigate(`${appConfig.paths.chatroom}?id=${item?.room_id}`,
                            {
                                state: { notificationMessage: extendedResult }
                            });
                    }
                } else {
                    // * error
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [getRelevantMessage, handleIsLoading, navigate]);

    const content = useMemo(() => {
        const message = isChineseLanguage
            ? notificationInfo?.notification_message_cn
            : notificationInfo?.notification_message;
        if (!message) return null;

        const pattern = isChineseLanguage ? /，并推荐到当地的聊天室/g : /,and recommend to the local chat rooms/g;

        // 将 message 中匹配到的 pattern 移除
        const parts = message.split(pattern);

        // 获取关联信息列表
        const associatedInfoList = notificationInfo.associated_info_list || [];

        const msg = associatedInfoList?.length > 0 ? message : parts;

        return (
            <Typography component="span" sx={{
                fontSize: "12px !important",
                fontWeight: 400,
            }}>
                {msg}&nbsp;
                {associatedInfoList.map((item) => (
                    <Typography 
                    key={item?.room_id} 
                    sx={{
                        color: "rgb(25, 118, 210)",
                        cursor: 'pointer',
                        fontSize: "12px !important",
                        fontWeight: 400,
                        display:"inline-block"
                    }}
                        onClick={e => {
                            e.stopPropagation();
                            handleIsLoading && handleIsLoading(true);
                            handleGetRelevantMessage(item);
                        }}
                    >
                        {item?.name}{t("app.chatroom.chatroom")} &nbsp; 
                    </Typography>
                ))}
            </Typography>
        )
    }, [handleGetRelevantMessage, isChineseLanguage, notificationInfo, t, handleIsLoading]);


    return (
        <ListItem
            disableRipple
            key={notificationInfo?.notification_id}
            sx={{
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: 'rgba(249, 253, 255, 1)'
                },
                "&:hover .deleteIcon": {
                    display: "inline-block",
                },
                alignItems: "flex-start !important",
                padding: "10px 0"
            }}
            onClick={handleClickItem}
            button
        >
            <ListItemAvatar sx={{ position: "relative" }}>
                <Avatar alt="avatar" src={notificationInfo?.user?.user_avatar || DefaultAvatar} />
                <Badge color="error" variant="dot" invisible={notificationInfo?.is_read} sx={{ position: 'absolute', top: "6px", right: "20px" }} />
            </ListItemAvatar>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'center',
                    width: "65%",
                    height: "100%",
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        width:"100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            fontFamily: "SF Pro Text",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#000000",
                            width: '50%', 
                            // overflow: 'hidden', 
                            textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap',
                        }}
                    >
                        {notificationInfo?.user?.user_name || notificationInfo?.user?.user_id}
                    </Box>
                    <Box
                        sx={{
                            fontFamily: "SF Pro Text",
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#999999",
                        }}
                    >
                        {howLongAgo(notificationInfo?.timestamp, t)}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '6px',
                        gap: '10px'
                    }}
                >
                    <Box
                        sx={{
                            fontFamily: "SF Pro Text",
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "#666666",
                            width: "100%",
                            height: "100%",
                            // textOverflow: "ellipsis",
                            // overflow: "hidden",
                            lineHeight: '14px',
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                            display: '-webkit-box',
                            '-webkit-box-orient': 'vertical',
                            textAlign: 'left'
                        }}
                    >
                        {content}
                    </Box>
                    <Box
                        component="img"
                        className="deleteIcon"
                        sx={{ display: '', zIndex: 10 }}
                        src={IcoDelete}
                        onClick={(e) => handleDeleteNotification(e, notificationInfo)}
                    />
                </Box>

            </Box>
            {notificationInfo?.post_message && (
                <div
                    style={{
                        width: "85px",
                        height: "58px",
                        marginLeft: "8px",
                        position: "relative",
                    }}
                >
                    <img alt='message' src={getCoverImageFromPostContent(notificationInfo?.post_message) || DefaultPicture}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover',
                            borderRadius: '5px',
                            border: "1px solid #f3f3f3"
                        }} />
                </div>
            )}
        </ListItem>
    );
};

export default NotificationListItem;
