import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  CheckoutOneTimeItem,
  PaymentMode,
  PaymentProductInfo,
  PaymentType,
  SessionInfo,
} from "../../models/payment";


export const paymentApi = createApi({
  reducerPath: "payment",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      getCheckoutSession: builder.mutation<
        Result<any>,
        {
          paymenttype: PaymentType;
          price_id: string;
          quantity: number;
          mode: PaymentMode;
          coupon: string;
          allow_input_code: boolean;
          success_url: string;
          cancel_url: string;
          product_info: PaymentProductInfo;
          description?: string;
          one_time_items?: CheckoutOneTimeItem[];
          trial_period_days?: number;
        }
      >({
        query: ({
          paymenttype,
          quantity,
          mode,
          price_id,
          coupon,
          allow_input_code,
          success_url,
          cancel_url,
          product_info,
          description,
          one_time_items,
          trial_period_days,
        }) => ({
          url: `getcheckoutsession`,
          method: "POST",
          body: {
            paymenttype: paymenttype,
            quantity: quantity,
            mode: mode,
            price_id: price_id,
            coupon: coupon,
            allow_input_code: allow_input_code,
            success_url: success_url,
            cancel_url: cancel_url,
            product_info: product_info,
            description: description,
            one_time_items,
            trial_period_days: trial_period_days,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res, "res"),
      }),
      makePayment: builder.mutation<
        Result<any>,
        {
          payment_request_id: string;
          paymentMessageType: PaymentType; //1: post message,reward 2: request 6: token recharge
          session_info?: SessionInfo;
          product_info?: PaymentProductInfo;
          sign_message?: {
            amount: number;
            to_user_id: string;
          };
          cart?: string;
        }
      >({
        query: ({
          payment_request_id,
          paymentMessageType,
          session_info,
          product_info,
          sign_message,
          cart,
        }) => ({
          url: `makepayment`,
          method: "POST",
          body: {
            payment_request_id: payment_request_id,
            payment_message_type: paymentMessageType,
            session_info: session_info,
            product_info: product_info,
            sign_message,
            cart,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      purchasePost: builder.mutation<
        Result<any>,
        {
          postID: string;
          publicKey: string;
        }
      >({
        query: ({ postID, publicKey }) => ({
          url: `makepayment`,
          method: "POST",
          body: {
            trade_type: "buy_post",
            payment_request_id: postID,
            buyer_public_key: publicKey,
            payment_message_type: 1,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      checkPostTrading: builder.mutation<
        Result<any>,
        {
          postID: string;
        }
      >({
        query: ({ postID }) => ({
          url: `post_trading`,
          method: "POST",
          body: {
            action: "prepare",
            message_id: postID,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      getWechatPayCodeUrl: builder.mutation<
        Result<any>,
        {
          amount: number,
        }
      >({
        query: ({
          amount
        }) => ({
          url: `getcheckoutsession`,
          method: "POST",
          body: {
            amount,
            mode: 0,
            paymenttype: 7,
            quantity: 0,
            wechat_pay_type: "native"
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res, "res"),
      }),
    };
  },
});

export const {
  useGetCheckoutSessionMutation,
  useMakePaymentMutation,
  usePurchasePostMutation,
  useCheckPostTradingMutation,
  useGetWechatPayCodeUrlMutation
} = paymentApi;
