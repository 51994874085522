import { useCallback, useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { appConfig } from "src/app-config";
import IcoRecommend from "src/images/root/ico-recommend.svg";
import { useGetRecommendListMutation } from "src/service/api/post-api";
import { useSelector } from "src/tools/toolkit/store";
import { isShowRecommendIcon } from "src/tools/utils/auth";

const RecommendIcon = () => {
  const theme = useTheme();
  const { credential } = useSelector((state) => state.app);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [getList] = useGetRecommendListMutation();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoop, setIsLoop] = useState<boolean>(true);

  const handleGetList = useCallback(() => {
    if (!credential?.user_id) {
      setIsShow(false);
      return;
    }
    const param = {
      page_size: 1,
      start_key: "",
      user_id: credential?.user_id,
      sort_type: "specific_recommend",
    };
    getList(param)
      .unwrap()
      .then((res) => {
        if (!res?.isFailed && res?.result?.itemList?.length>0) {
          const dataList = res?.result?.itemList?.length;
          setIsLoop(false);
          setIsShow(Boolean(dataList));
        } else {
          setIsShow(false);
        }
      });
  }, [getList, credential?.user_id]);

  useEffect(() => {
    if (!isLoop) return;
    const timer = setInterval(() => {
      handleGetList();
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [handleGetList, isLoop]);

  return (
    <Box>
      {isShow && isShowRecommendIcon() && (
        <Box component={Link} to={appConfig.paths.recommend}>
          <Box
            component="img"
            src={IcoRecommend}
            sx={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              zIndex: 1000,
              cursor: "pointer",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default RecommendIcon;
