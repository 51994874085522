import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, BaseQueryApi } from '@reduxjs/toolkit/query/react'
// import { getStoredAccessToken, storeAccessToken } from 'src/utils/localStorage'
// import { showLogoutDialog } from '../service/signinup-slice'
// import { getFormatDisplayTime } from 'src/utils/timeUtil'
// import  Axios, { AxiosError, AxiosResponse } from 'axios';
import { ThunkDispatch } from '@reduxjs/toolkit'
// import { getFormatDisplayTime } from '../utils/timeUtil'
import { decodeJWT } from '../utils/auth'
import { RootState } from './store';
import { signInRefreshToken } from '../utils/refreshToken';
import { checkResponse } from '../utils/api';
import { defaultLanguage } from '../../models/locale';
import { setRefreshToken, setToken } from '../../service/redux/access-slice';
import { logout } from '../utils/store';
import { getToken, getRefreshToken } from "src/tools/utils/storage"
import { fetchWithErrorHandling, ErrorType, ErrorSubType, getExtraData } from "src/tools/utils/reportError";
import Axios from 'axios';
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { curryRight } from 'lodash';

const TOKEN_REFRESH_WHITE_LIST_APIS = [
  'signinpw',
  'thirdpartylogin',
  'signupcheckname',
  'signup',
  'signupverifycode',
  'getuserprofile',
  'signin',
  'forgetpassword',
];

export const queryBuilder = (path?: string) => ({
  baseUrl: path ?? process.env.REACT_APP_API_URL,
  prepareHeaders(headers: any, { getState }: any) {
    const { app } = getState() as RootState
    const token = getToken() ?? '';
    if (Boolean(token)) {
      // const localToken = localStorage.getItem(settings.keys.accessToken);
      // let token=tokenRes.token;
      // if (localToken && localToken !== tokenRes.token) {
      //   // setToken(localToken);
      //   token=localToken;
      // }
      headers.set('Authorization', `${token}`)
      // headers.set('Authorization', `${token}`)
    }
    // headers.set('Authorization', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiJ3dWNoZW4iLCJ1c2VybmFtZSI6IiIsInVzZXJhdmF0YXIiOiIiLCJncHMiOiIzNC4zMjA2NTMsMTA4Ljc3ODI5MyIsImFjY3VyYWN5IjoxLCJpc19hbm9ueW1vdXMiOmZhbHNlLCJ1c2VyX3ByaXZpbGVnZSI6MiwiaWF0IjoxNjg0NDg0MTI3LCJleHAiOjE2ODQ0ODc3Mjd9.PIC7pBcSfWDMokyfWf3-TT_gNtuZSQtLN_OtzFuy4ww`)
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    headers.set('Accept-Language', app.language ?? defaultLanguage)
    headers.set('Client-Type', 'web')
    headers.set('Client-Version', '0.1.0')
    return headers
  }
})

const baseQuery = fetchBaseQuery(queryBuilder());
// let requestRefreshTokenPromise: null | Promise<void> = null;
// export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
//   args,
//   api,
//   extraOptions
// ) => {
//   console.log('args:', args);
//   // const { tokenRes } = api.getState() as RootState
//   const token = getToken() ?? '';
//   const refreshToken = getRefreshToken() ?? '';
//   if (!token) {
//     return queryWithReportError(args, api, extraOptions);
//   }
//   const tokenData = decodeJWT(token);
//   const currentTimeSeconds = new Date().getTime() / 1000;
//   // console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
//   if (tokenData && tokenData.exp > currentTimeSeconds) {
//     // if (tokenData.exp - currentTimeSeconds > 59*60) {
//     return queryWithReportError(args, api, extraOptions);
//   }
//   if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
//     // console.log("This api doesn't need token refresh: ", (args as any).url)
//     return queryWithReportError(args, api, extraOptions);
//   }

//   if (tokenData && tokenData.userid && refreshToken) {
//     if (!requestRefreshTokenPromise) {
//       requestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
//     }
//     await requestRefreshTokenPromise;
//     requestRefreshTokenPromise = null;
//   }
//   return queryWithReportError(args, api, extraOptions);
// };

function isValidToken(token) {
  return typeof token === 'string' && token.length > 0;
}

const requestRefreshToken = async (dispatch: ThunkDispatch<any, any, any>, userId: string, refreshToken: string | null | undefined) => {
  if (refreshToken) {
    const refreshTokenData = decodeJWT(refreshToken);
    const currentTimeSeconds = new Date().getTime() / 1000;
    if (refreshTokenData.exp < currentTimeSeconds) {
      logout();
      return;
    }
  } else {
    logout();
    return;
  }
  const response = await signInRefreshToken(userId, refreshToken);
  if (response && checkResponse(response)) {
    const { token, refresh_token } = response.data?.data;
    if (!response.data?.data || !isValidToken(token) || !isValidToken(refresh_token)) {
      logout();
      return;
    }
    dispatch(setToken(token));
    dispatch(setRefreshToken(refresh_token));
  } else {
    logout();
  }
}
const queryWithReportError = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}, baseUrl?: string) => {

  let query = baseQuery;
  if (baseUrl) {
    query = fetchBaseQuery(queryBuilder(baseUrl))
  }
  const startTime = performance.now();
  const res = await query(args, api, extraOptions);

  const endTime = performance.now();


  const duration = endTime - startTime;
  if (res.error || !res.data || ((res.data as any).result && (res.data as any).result.code !== 201000)) {
    try {
      let url = '';
      let body: string | null = null;
      let authorization = "";
      let requestHeaders = {};
      const request = res.meta?.request;
      const response = res.meta?.response;
      const description = {
        request: {},
        response: {},
        duration,
        fetchResult: {},
        axiosResult: {},
        ...getExtraData()
      }
      if (request) {
        let bodyText = '';
        if (typeof args === 'object') {
          bodyText = args.body
        }

        if (bodyText === '' && !request.bodyUsed) {
          bodyText = await request.json();
        }

        const headers = {} as any
        request.headers.forEach((value, name) => {
          headers[name] = value;
        });

        requestHeaders = headers;

        if (request.url) {
          url = request.url;
        }

        const requestInfo = {
          method: request.method,
          url: request.url,
          headers: headers,
          body: bodyText || null, // 如果 body 为空则返回 null
          credentials: request.credentials,
          mode: request.mode,
          cache: request.cache,
          redirect: request.redirect,
          referrer: request.referrer,
          referrerPolicy: request.referrerPolicy,
          integrity: request.integrity,
        };
        body = requestInfo.body;
        authorization = requestInfo.headers?.authorization;

        description.request = requestInfo;

      }

      if (response) {
        const headers = {};
        response.headers.forEach((value, name) => {
          headers[name] = value;
        });

        if (response.url && url === '') {
          url = response.url;
        }

        const responseInfo = {
          status: response.status,
          statusText: response.statusText,
          url: response.url,
          headers: headers,
          body: res.data || null, // 如果 body 为空则返回 null
          ok: response.ok,
          redirected: response.redirected,
          type: response.type,
          bodyUsed: response.bodyUsed,
        };
        description.response = responseInfo;
      }


      // 获取请求body
      // 获取请求 token

      // 增加fetch
      let fetchResult;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify(body)
        })
        const responseBody = await response.json();
        const responseHeaders: Record<string, string> = {}
        for (let h of response.headers.entries() as any) {
          responseHeaders[h[0]] = h[1];
        }

        fetchResult = {
          body: responseBody,
          headers: responseHeaders,
          status: response.status,
          statusText: response.statusText
        };

      } catch (e) {
        if (e instanceof Error) {
          fetchResult = e.message;
        } else {
          fetchResult = 'unknown error';
        }


      }
      // 增加axios
      let axiosResult;
      try {
        axiosResult = await Axios.post(url, body, {
          headers: requestHeaders
        });
      } catch (e) {
        if (e instanceof Error) {
          axiosResult = e.message;
        } else {
          axiosResult = 'unknown error';
        }
      }
      description.fetchResult = fetchResult;
      description.axiosResult = axiosResult;
      const content = res.error || (res.data as any).result;
      fetchWithErrorHandling({
        url,
        type: ErrorType.ERROR,
        sub_type: ErrorSubType.SERVER_ERROR,
        content: JSON.stringify(content),
        description: JSON.stringify(description),
      })
    } catch (e) {
      console.error('fetchWithErrorHandling:', e);
    }
  }

  if ((res.meta?.response?.status === 200 && res.data && (res.data as any).result?.result_code === 401) || res.meta?.response?.status === 401) {
    // console.log("api re`turn 401 token expired, begin to refresh token...")
    // const { tokenRes } = api.getState() as RootState;
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    const tokenData = decodeJWT(token);
    if (tokenData && tokenData.userid && refreshToken) {
      await requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
      // console.log("api 401 token refresh finished, request again...")
      return await query(args, api, extraOptions);
    } else {
      logout();
    }
  }
  return res;
}
export const buildQueryWithReAuth = (baseUrl?: string) => {
  let adminRequestRefreshTokenPromise: null | Promise<void> = null;
  const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
  ) => {
    // const { tokenRes } = api.getState() as RootState
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    if (!token) {
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    const tokenData = decodeJWT(token);
    const currentTimeSeconds = new Date().getTime() / 1000;
    // console.log("old token expire time: ", getFormatDisplayTime(tokenData?.exp * 1000))
    if (tokenData && tokenData.exp > currentTimeSeconds) {
      // if (tokenData.exp - currentTimeSeconds > 59*60) {
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
      // console.log("This api doesn't need token refresh: ", (args as any).url)
      return queryWithReportError(args, api, extraOptions, baseUrl);
    }
    if (!adminRequestRefreshTokenPromise && tokenData?.userid && refreshToken) {
      adminRequestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
    }
    await adminRequestRefreshTokenPromise;
    adminRequestRefreshTokenPromise = null;
    return queryWithReportError(args, api, extraOptions, baseUrl);
  };
  return baseQueryWithReAuth;
}

interface AxiosArgs {
  url: string,
  method?: AxiosRequestConfig['method']
  body?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
}
type AxiosQueryFn = BaseQueryFn<AxiosArgs, unknown, unknown, {}, { response: AxiosResponse, request: AxiosRequestConfig }>

const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' }
): AxiosQueryFn => async ({ url, method, body, params, headers }) => {
  try {
    if (headers === undefined) {
      headers = {};
    }

    const token = getToken() ?? '';

    if (Boolean(token)) {
      headers['Authorization'] = token;
    }

    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    // headers['Accept-Language'] = token;
    headers['Client-Type'] = 'web';
    headers['Client-Version'] = '0.1.0';

    let requestUrl = '';
    try {
      new URL(url);
      requestUrl = url;
    } catch (e) {
      let flag = false;
      if (baseUrl.charAt(baseUrl.length - 1) !== '/' && url.charAt(0) !== '/') {
        flag = true
      }
      requestUrl = baseUrl + (flag ? '/' : '') + url;
    }

    const result = await Axios({
      url: requestUrl,
      method,
      data: body,
      params,
      headers
    })
    return {
      data: result.data, meta: { response: result, request: result.config }
    }
  } catch (e) {
    const err = e as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }, meta: { response: err.response as AxiosResponse, request: err.config }
    }
  }
}
// const baseAxiosQuery = axiosBaseQuery({ baseUrl: process.env.REACT_APP_API_URL ?? "" })


const axiosQuery = async (args: AxiosArgs, api: BaseQueryApi, extraOptions: {}, baseUrl: string) => {

  let query = axiosBaseQuery({ baseUrl })
  // if (baseUrl) {
  //   query = axiosBaseQuery({ baseUrl })
  // }
  const startTime = performance.now();
  const res = await query(args, api, extraOptions);
  const endTime = performance.now();


  const duration = endTime - startTime;

  if (res.error || !res.data || ((res.data as any).result && (res.data as any).result.code !== 201000)) {
    let url = "";
    let headers = {};

    const description = {
      error: res.error,
      request: {},
      response: {},
      duration,
      fetchResult: {},
      axiosResult: {},
      ...getExtraData()
    }

    if (res.meta?.response) {

      description.response = res.meta.response;

    }

    if (res.meta?.request) {
      url = res.meta.request.url as string;
      description.request = res.meta.request;
      headers = res.meta.request.headers;
    }


    // 增加fetch
    // let fetchResult;
    // try {
    //   const response = await fetch(url, {
    //     method: args.method,
    //     headers: headers,
    //     body: JSON.stringify(args.body)
    //   })
    //   const responseBody = await response.json();
    //   const responseHeaders: Record<string, string> = {}
    //   for (let h of response.headers.entries() as any) {
    //     responseHeaders[h[0]] = h[1];
    //   }

    //   fetchResult = {
    //     body: responseBody,
    //     headers: responseHeaders,
    //     status: response.status,
    //     statusText: response.statusText
    //   };

    // } catch (e) {
    //   if (e instanceof Error) {
    //     fetchResult = e.message;
    //   } else {
    //     fetchResult = 'unknown error';
    //   }
    // }

    // 增加axios
    // let axiosResult;
    // try {
    //   axiosResult = await Axios.post(url, args.body, {
    //     headers
    //   });
    // } catch (e) {
    //   if (e instanceof Error) {
    //     axiosResult = e.message;
    //   } else {
    //     axiosResult = 'unknown error';
    //   }
    // }
    // description.fetchResult = fetchResult;
    // description.axiosResult = axiosResult;
    const content = res.error || (res.data as any).result;
    fetchWithErrorHandling({
      url,
      type: ErrorType.ERROR,
      sub_type: ErrorSubType.SERVER_ERROR,
      content: JSON.stringify(content),
      description: JSON.stringify(description),
    })
  }


  if ((res.meta?.response?.status === 200 && res.data && (res.data as any).result?.result_code === 401) || res.meta?.response?.status === 401) {
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    const tokenData = decodeJWT(token);
    if (tokenData && tokenData.userid && refreshToken) {
      await requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
      // console.log("api 401 token refresh finished, request again...")
      return await query(args, api, extraOptions);
    } else {
      logout();
    }
  }
  return res;
}


// const baseAxiosQueryWithReAuth: AxiosQueryFn = async (args, api, extraOptions) => {
//   console.log('args:', args);
//   const token = getToken() ?? '';
//   const refreshToken = getRefreshToken() ?? '';
//   if (!token) {
//     return axiosQuery(args, api, extraOptions);
//   }
//   const tokenData = decodeJWT(token);
//   const currentTimeSeconds = new Date().getTime() / 1000;
//   if (tokenData && tokenData.exp > currentTimeSeconds) {
//     // if (tokenData.exp - currentTimeSeconds > 59*60) {
//     return axiosQuery(args, api, extraOptions);
//   }
//   if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
//     // console.log("This api doesn't need token refresh: ", (args as any).url)
//     return axiosQuery(args, api, extraOptions);
//   }

//   if (tokenData && tokenData.userid && refreshToken) {
//     if (!requestRefreshTokenPromise) {
//       requestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
//     }
//     await requestRefreshTokenPromise;
//     requestRefreshTokenPromise = null;
//   }

//   return axiosQuery(args, api, extraOptions);
// }

const buildBaseAxiosQueryWithReAuth = (baseUrl: string): AxiosQueryFn => {
  let requestRefreshTokenPromise: null | Promise<void> = null;
  const curryAxiosQuery = curryRight(axiosQuery);
  const baseQuery = curryAxiosQuery(baseUrl);
  const baseAxiosQueryWithReAuth: AxiosQueryFn = async (args, api, extraOptions) => {
    const token = getToken() ?? '';
    const refreshToken = getRefreshToken() ?? '';
    if (!token) {
      return baseQuery(args, api, extraOptions);
    }
    const tokenData = decodeJWT(token);
    const currentTimeSeconds = new Date().getTime() / 1000;
    if (tokenData && tokenData.exp > currentTimeSeconds) {
      // if (tokenData.exp - currentTimeSeconds > 59*60) {
      return baseQuery(args, api, extraOptions);
    }
    if (TOKEN_REFRESH_WHITE_LIST_APIS.includes((args as any).url)) {
      // console.log("This api doesn't need token refresh: ", (args as any).url)
      return baseQuery(args, api, extraOptions);
    }

    if (tokenData && tokenData.userid && refreshToken) {
      if (!requestRefreshTokenPromise) {
        requestRefreshTokenPromise = requestRefreshToken(api.dispatch, tokenData.userid, refreshToken);
      }
      await requestRefreshTokenPromise;
      requestRefreshTokenPromise = null;
    }

    return baseQuery(args, api, extraOptions);
  }

  return baseAxiosQueryWithReAuth
}

export const baseQueryWithReauth = buildBaseAxiosQueryWithReAuth(process.env.REACT_APP_API_URL ?? "");
export const baseAdminQueryWithReAuth = buildBaseAxiosQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL ?? "");