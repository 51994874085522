import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile, TimePostEntity, CommonUser } from "src/models/user";
import { PostEntity } from 'src/models/post';
import { settings } from "../settings";
import { LocationEntity } from "src/models/location";


interface BaseInfo<T> {
  userId: string;
  items: T[];
  startKey: string;
  hasMore: boolean;
}

export enum PostType {
  all = 1,
  free = 2,
  paid = 3
}

interface PostInfo extends BaseInfo<PostEntity>  {
  post_type: PostType;
};

type TimePostInfo = BaseInfo<TimePostEntity>;

type FollowUserInfo = BaseInfo<CommonUser>;

type FollowLocationInfo = BaseInfo<LocationEntity>;

interface UserPageInfo {
  userRecord: Record<string, UserProfile>;
  postRecord: Record<string, PostInfo>;
  dayPostRecord: Record<string, TimePostInfo>;
  monthPostRecord: Record<string, TimePostInfo>;
  yearPostRecord: Record<string, TimePostInfo>;
  followers: Record<string, FollowUserInfo>;
  followingPeople: Record<string, FollowUserInfo>;
  friends: Record<string, FollowUserInfo>;
  followingLocations: Record<string, FollowLocationInfo>;
}



const _loadState = (): UserPageInfo => {
  return {
    userRecord: {},
    postRecord: {},
    dayPostRecord: {},
    monthPostRecord: {},
    yearPostRecord: {},
    followers: {},
    followingPeople: {},
    friends: {},
    followingLocations: {}
  }
}


const userSlice = createSlice({
  name: settings.keys.userPageInfo,
  initialState: _loadState(),
  reducers: {
    setUser(state, action: PayloadAction<UserProfile>) {
      state.userRecord[action.payload.user_id] = action.payload;
    },
    setPost(state, action: PayloadAction<PostInfo>) {
      const currentData = state.postRecord[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
        currentData.post_type = action.payload.post_type;
      } else {
        state.postRecord[action.payload.userId] = action.payload;
      }
    },
    updatePost(state, action: PayloadAction<{ post: PostEntity, userId: string, postIndex: number }>) {
      const currentData = state.postRecord[action.payload.userId];
      if (currentData) {
        const newItems = [...currentData.items];
        newItems[action.payload.postIndex] = action.payload.post;
        currentData.items = newItems;
      }
    },
    deletePost(state, action: PayloadAction<PostEntity>) {
      const currentData = state.postRecord[action.payload.userid];
      if (currentData) {
        currentData.items = currentData.items.filter((item) => item.messageid !== action.payload.messageid)
      }
    },
    RefreshPurchaseStatus(state, action: PayloadAction<PostEntity>) {
      const currentData = state.postRecord[action.payload.userid];
      if (currentData) {
        currentData.items = currentData.items.map(item =>
          item.messageid === action.payload.messageid
            ? action.payload
            : item
        );
      }
    },
    clearPost(state, action: PayloadAction<string>) {
      state.postRecord[action.payload] = {
        userId: action.payload,
        items: [],
        startKey: "",
        hasMore: true,
        post_type: PostType.all
      }
    },
    setDayPost(state, action: PayloadAction<TimePostInfo>) {
      const currentData = state.dayPostRecord[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.dayPostRecord[action.payload.userId] = action.payload;
      }
    },
    setMonthPost(state, action: PayloadAction<TimePostInfo>) {
      const currentData = state.monthPostRecord[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.monthPostRecord[action.payload.userId] = action.payload;
      }
    },
    setYearPost(state, action: PayloadAction<TimePostInfo>) {
      const currentData = state.yearPostRecord[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.yearPostRecord[action.payload.userId] = action.payload;
      }
    },
    setFollowers(state, action: PayloadAction<FollowUserInfo>) {
      const currentData = state.followers[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.followers[action.payload.userId] = action.payload;
      }
    },
    setFollowingPeople(state, action: PayloadAction<FollowUserInfo>) {
      const currentData = state.followingPeople[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.followingPeople[action.payload.userId] = action.payload;
      }
    },
    setFriends(state, action: PayloadAction<FollowUserInfo>) {
      const currentData = state.friends[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.friends[action.payload.userId] = action.payload;
      }
    },
    setFollowingLocations(state, action: PayloadAction<FollowLocationInfo>) {
      const currentData = state.followingLocations[action.payload.userId];
      if (currentData) {
        currentData.items = [...currentData.items, ...action.payload.items];
        currentData.startKey = action.payload.startKey;
        currentData.hasMore = action.payload.hasMore;
      } else {
        state.followingLocations[action.payload.userId] = action.payload;
      }
    },
  }
});


export const {
  setUser,
  setPost,
  updatePost,
  deletePost,
  RefreshPurchaseStatus,
  clearPost,
  setDayPost,
  setMonthPost,
  setYearPost,
  setFollowers,
  setFollowingLocations,
  setFollowingPeople,
  setFriends,
} = userSlice.actions;
export default userSlice.reducer;
