import { configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import app from "../../service/redux/app-slice";
import tokenRes from "../../service/redux/access-slice";
import signinup from "../../service/redux/signinup-slice";
import feedback from "src/service/redux/feedback-slice"
import location from "../../service/redux/location-slice";
import refreshList from "src/service/redux/refresh-list-slice";
import search from "../../service/redux/search-slice";
import video from 'src/service/redux/video-slice';
import mapLoad from 'src/service/redux/map-load-slice';
import userPageInfo from 'src/service/redux/user-page-info-slice';
import promotion from 'src/service/redux/promotion-slice';
import { authApi } from "../../service/api/auth-api";
import { homeApi } from "../../service/api/home-api";
import { userApi } from "../../service/api/user-api";
import { requestApi } from "../../service/api/request-api";
import { paymentApi } from "../../service/api/payment-api";
import { postApi } from "../../service/api/post-api";
import { followApi } from "../../service/api/follow-api";
import { searchApi } from "../../service/api/search-api";
import { notificationApi } from "../../service/api/notification-api";
import { productApi } from "../../service/api/product-api";
import { customerApi } from "../../service/api/customer-api";
import { orderApi } from "../../service/api/order-api";
import { thirdPartyLoginApi } from "src/service/api/thirdPartyLogin-api";
import { walletApi } from "src/service/api/wallet-api";
import { logApi } from "src/service/api/log-api";
import { newsApi } from "src/service/api/news-api";
import { adminNewsApi } from "src/service/api/admin-news-api";
import { uploadApi } from "src/service/api/upload-file-api";
import { saveListApi } from "src/service/api/saveList-api";
import { signUpApi } from "src/service/api/signup-api";
import { questionnaireApi } from "src/service/api/questionnaire-api";
import { adminPostApi } from "src/service/api/admin-post-api";
import { adminRoleApi } from "src/service/api/admin-role-api"
import { adminRequestApi } from "src/service/api/admin-request-api";
import { adminDashBoardApi } from "src/service/api/admin-dash-board-api"
import { adminPromotionApi } from "src/service/api/admin-promotion-api"
import { configApi } from "src/service/api/config-api"
import { adminChatroomApi } from "src/service/api/admin-chatroom-api"
import { adminTravelApi } from "src/service/api/admin-travel-api"
import { adminFeedbackApi } from "src/service/api/admin-feedback-api"
import { adminKnowledgeApi } from "src/service/api/admin-knowledge-api"
import { pingxxApi } from "src/service/api/pingxx-api";
import { chatroomApi } from "src/service/api/chatroom-api";
import { travelApi } from "src/service/api/travel-api"
import { feedbackApi } from "src/service/api/feedback-api"
import { memberApi } from "src/service/api/member-api"

export const toolkitStore = configureStore({
  reducer: {
    app,
    tokenRes,
    signinup,
    feedback,
    location,
    search,
    video,
    refreshList,
    mapLoad,
    userPageInfo,
    promotion,
    [authApi.reducerPath]: authApi.reducer,
    [signUpApi.reducerPath]: signUpApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [followApi.reducerPath]: followApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [thirdPartyLoginApi.reducerPath]: thirdPartyLoginApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [saveListApi.reducerPath]: saveListApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [adminNewsApi.reducerPath]: adminNewsApi.reducer,
    [adminPostApi.reducerPath]: adminPostApi.reducer,
    [adminRoleApi.reducerPath]: adminRoleApi.reducer,
    [adminRequestApi.reducerPath]: adminRequestApi.reducer,
    [adminDashBoardApi.reducerPath]: adminDashBoardApi.reducer,
    [adminPromotionApi.reducerPath]: adminPromotionApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [adminChatroomApi.reducerPath]: adminChatroomApi.reducer,
    [adminTravelApi.reducerPath]: adminTravelApi.reducer,
    [adminFeedbackApi.reducerPath]: adminFeedbackApi.reducer,
    [adminKnowledgeApi.reducerPath]: adminKnowledgeApi.reducer,
    [questionnaireApi.reducerPath]: questionnaireApi.reducer,
    [pingxxApi.reducerPath]: pingxxApi.reducer,
    [chatroomApi.reducerPath]: chatroomApi.reducer,
    [travelApi.reducerPath]: travelApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      //https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: {
        ignoredActionPaths: [
          "payload",
          "meta.baseQueryMeta.request",
          "meta.baseQueryMeta.response",
        ],
        ignoredPaths: [
          "refreshList.refreshFunctionRecord",
          "auth.mutations",
          "auth.queries",
          "signUp.mutations",
          "signUp.queries",
          "home.mutations",
          "home.queries",
          "user.mutations",
          "user.queries",
          "request.mutations",
          "request.queries",
          "payment.mutations",
          "payment.queries",
          "post.mutations",
          "post.queries",
          "follow.mutations",
          "follow.queries",
          "searchApi.mutations",
          "searchApi.queries",
          "notification.mutations",
          "notification.queries",
          "product.mutations",
          "product.queries",
          "customer.mutations",
          "customer.queries",
          "order.mutations",
          "order.queries",
          "thirdPartyLogin.mutations",
          "thirdPartyLogin.queries",
          "wallet.mutations",
          "wallet.queries",
          "log.mutations",
          "saveList.mutations",
          "saveList.queries",
          "upload-file.mutations",
          "news.mutations",
          "news.queries",
          "adminNews.mutations",
          "adminNews.queries",
          "adminPost.mutations",
          "adminPost.queries",
          "adminRole.mutations",
          "adminRole.queries",
          "adminRequest.mutations",
          "adminRequest.queries",
          "adminDashBoard.mutations",
          "adminDashBoard.queries",
          "adminPromotion.mutations",
          "adminPromotion.queries",
          "config.mutations",
          "config.queries",
          "adminChatroom.mutations",
          "adminChatroom.queries",
          "adminKnowledge.mutations",
          "adminKnowledge.queries",
          "questionnaire.mutations",
          "questionnaire.queries",
          "pingxx.queries",
          "pingxx.mutations",
          "chatroom.queries",
          "chatroom.mutations",
          "travel.queries",
          "travel.mutations",
          "adminTravel.queries",
          "adminTravel.mutations",
          "feedback_api.queries",
          "feedback_api.mutations",
          "adminFeedback.queries",
          "adminFeedback.mutations",
          "member_api.queries",
          "member_api.mutations",
        ],
      },
    })
      .concat(authApi.middleware)
      .concat(signUpApi.middleware)
      .concat(homeApi.middleware)
      .concat(userApi.middleware)
      .concat(requestApi.middleware)
      .concat(paymentApi.middleware)
      .concat(postApi.middleware)
      .concat(followApi.middleware)
      .concat(searchApi.middleware)
      .concat(notificationApi.middleware)
      .concat(productApi.middleware)
      .concat(customerApi.middleware)
      .concat(orderApi.middleware)
      .concat(thirdPartyLoginApi.middleware)
      .concat(walletApi.middleware)
      .concat(logApi.middleware)
      .concat(saveListApi.middleware)
      .concat(newsApi.middleware)
      .concat(adminNewsApi.middleware)
      .concat(adminPostApi.middleware)
      .concat(adminRoleApi.middleware)
      .concat(adminRequestApi.middleware)
      .concat(adminDashBoardApi.middleware)
      .concat(adminPromotionApi.middleware)
      .concat(configApi.middleware)
      .concat(adminChatroomApi.middleware)
      .concat(adminTravelApi.middleware)
      .concat(adminFeedbackApi.middleware)
      .concat(adminKnowledgeApi.middleware)
      .concat(questionnaireApi.middleware)
      .concat(uploadApi.middleware)
      .concat(pingxxApi.middleware)
      .concat(chatroomApi.middleware)
      .concat(travelApi.middleware)
      .concat(feedbackApi.middleware)
      .concat(memberApi.middleware)
  },
});

export type AppDispatch = typeof toolkitStore.dispatch;
export type RootState = ReturnType<typeof toolkitStore.getState>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
