import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      searchUser: builder.mutation<
        Result<any>,
        { pageSize?: number; startFrom?: string; searchInfo: string }
      >({
        query: ({ pageSize, startFrom, searchInfo }) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: {
            type: 1,
            page_size: pageSize || 20,
            start_key: startFrom,
            search_content: searchInfo,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      searchLocation: builder.mutation<
        Result<any>,
        { pageSize?: number; startFrom?: number; searchInfo: string }
      >({
        query: ({ pageSize, startFrom, searchInfo }) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: {
            type: 2,
            page_size: pageSize || 20,
            start_from: startFrom,
            search_content: searchInfo,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      searchPost: builder.mutation<
        Result<any>,
        { pageSize?: number; startFrom?: string; searchInfo: string }
      >({
        query: ({ pageSize, startFrom, searchInfo }) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: {
            type: 3,
            page_size: pageSize || 20,
            start_key: startFrom,
            search_content: searchInfo,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      searchLive: builder.mutation<
        Result<any>,
        { pageSize?: number; startFrom?: number; searchInfo: string }
      >({
        query: ({ pageSize, startFrom, searchInfo }) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: {
            type: 4,
            page_size: pageSize || 20,
            start_from: startFrom,
            search_content: searchInfo,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      searchTravel: builder.mutation<
        Result<any>,
        { pageSize?: number; startFrom?: string; searchInfo: string }
      >({
        query: ({ pageSize, startFrom, searchInfo }) => ({
          url: `fuzzy_search`,
          method: "POST",
          body: {
            type: 5,
            page_size: pageSize || 20,
            start_key: startFrom,
            search_content: searchInfo,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      searchNearby: builder.mutation<
        Result<any>,
        { pageSize?: number; forwardOrder?: boolean; searchName: string }
      >({
        query: ({ pageSize, forwardOrder, searchName }) => ({
          url: `searchlocationbyname`,
          method: "POST",
          body: {
            pagesize: pageSize || 0,
            forwardorder: false,
            search_name: searchName,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      getHistory: builder.mutation<Result<string[]>,{}>({
        query: () => ({
          url: `hot_search`,
          method: "POST",
          body: {
            type:"hot_search"
          },
        }),
        transformResponse: (res: Response<string[]>) => new Value(res),
      }),
    };
  },
});

export const {
  useSearchUserMutation,
  useSearchLocationMutation,
  useSearchTravelMutation,
  useSearchPostMutation,
  useSearchLiveMutation,
  useSearchNearbyMutation,
  useGetHistoryMutation,
} = searchApi;
