import CommonDialogBox from "../box/CommonDialogBox";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useSelector } from "src/tools/toolkit/store";
import { useDispatch } from "react-redux";
import {
  setShowFeedbackDialog,
  setCloseFeedbackAlert,
} from "src/service/redux/feedback-slice";

const FeedbackTipDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showFeedbackAlert } = useSelector((state) => state.feedback);
  const handleCancel = () => {
    dispatch(setCloseFeedbackAlert());
  };
  const handleConfirm = () => {
    dispatch(setCloseFeedbackAlert());
    dispatch(setShowFeedbackDialog());
  };

  return (
    <CommonDialogBox
      open={showFeedbackAlert}
      handleClose={handleCancel}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      confirmText={t("app.settings.feedback.feedback")}
      dialogTitle={t("app.settings.feedback.hint")}
    >
      <Typography>{t("app.settings.feedback.hint_des")}</Typography>
    </CommonDialogBox>
  );
};

export default FeedbackTipDialog;
