// import Loadable from 'src/components/loadable/Loadable';
import { type RouteObject } from "react-router-dom";
import { paths } from 'src/app-config';
import EmptyLayout from '../EmptyLayout';


import Loadable from "src/components/loadable/Loadable";


const MapPage = Loadable(async () => await import("src/pages/map/MapPage"));
const RootHomePage = Loadable(async () => await import("src/pages/root/RootHome"));
const OpenAppPage = Loadable(async () => await import("src/layout/openApp/page"));
const AppWallet = Loadable(async () => await import("src/pages/wallet/AppWallet"));
const SharedNewsDetail = Loadable(async () => await import("src/pages/news/SharedNewsDetailPage"));
const SharedMessageDetail = Loadable(async () => await import("src/pages/details-web/SharedMessageDetail"));
const SharedLocationPage = Loadable(async () => await import("src/pages/location"));
const SharedCollectionPage = Loadable(async () => await import("src/pages/collection/SharedCollectionPage"));

const AdminAppWithdraw = Loadable(async () => await import("src/pages/admin/withdraw/AppWithdraw"));

const LocationAreaPage = Loadable(async () => await import("src/pages/location-area/LocationArea"));

const AndroidTestPage = Loadable(async () => await import("src/pages/android-test/AndroidTest"));
const SearchHistory = Loadable(async () => await import("src/pages/search-mobile/History"));
const SharedSearchPage = Loadable(async () => await import("src/pages/search/index"));
const UserRedirectPage = Loadable(async () => await import("src/pages/user/UserRedirect"));


const EmptyLayoutRoutes: RouteObject = {
  path: '/',
  element: <EmptyLayout />,
  children: [{
    path: paths.aboutUs,
    element: <RootHomePage />,
  },
  {
    path: paths.openApp,
    element: <OpenAppPage />,
  },
  {
    path: paths.sharedNewsDetail,
    element: <SharedNewsDetail />,
  },
  {
    path: paths.details,
    element: <SharedMessageDetail />,
  },
  {
    path: paths.sharedLocation,
    element: <SharedLocationPage />,
  },
  {
    path: paths.sharedUser,
    element: <UserRedirectPage />,
  },
  {
    path: paths.mapMobile,
    element: <MapPage />,
  },
  {
    path: paths.appWallet,
    element: <AppWallet />,
  },
  {
    path: paths.default,
    element: <RootHomePage />,
  },
  {
    path: paths.sharedCollection,
    element: <SharedCollectionPage />,
  },
  {
    path: paths.adminAppWithdraw,
    element: <AdminAppWithdraw />,
  },
  {
    path: paths.sharedLocationArea,
    element: <LocationAreaPage />,
  },
  {
    path: paths.androidTest,
    element: <AndroidTestPage />
  },
  {
    path: paths.searchHistory,
    element: <SearchHistory />
  },
  {
    path: paths.sharedSearch,
    element: <SharedSearchPage />
  }
  ]
}

export default EmptyLayoutRoutes;