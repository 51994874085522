import { Dialog, DialogActions, DialogContent, IconButton, DialogTitle, CircularProgress, Box, FormControl, Stack, FormHelperText } from "@mui/material";
import { FC, ReactNode } from "react";
import { PaperComponent } from "src/components/styled/Paper";
import { useTranslation } from "react-i18next";
import CancelButton from "src/components/styled/CancelButton";
import ConfirmButton from "src/components/styled/ConfirmButton";
import { makeStyles } from "tss-react/esm/mui";
import CloseIcon from '@mui/icons-material/Close';
import BackIco from "src/images/dialog/common/ico-back.svg"

const useStyles = makeStyles()((theme) => {
  return {
    dialogContent: {
      borderBottom: 0,
      [theme.breakpoints.down('sm')]: {
        width: "80vw"
      }
    },
  }
})

interface Props {
  singleButton?: boolean;
  loading?: boolean;
  open: boolean;
  handleClose: () => void;
  dialogTitle: string;
  cancelText?: string;
  confirmText?: string;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  handleBack?: () => void;
  children: ReactNode;
  isHaveButton?: boolean;
  width?: string;
  error?: string;
}

const CommonDialogBox: FC<Props> = ({
  singleButton,
  loading,
  dialogTitle,
  open,
  handleClose,
  cancelText,
  confirmText,
  handleCancel,
  handleConfirm,
  children,
  isHaveButton = true,
  width = "600px",
  handleBack,
  error
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <>
      <Dialog open={open} maxWidth='lg' PaperComponent={PaperComponent} onClick={e => e.stopPropagation()}>
        <DialogTitle sx={{ p: "2 4", fontWeight: 700 }}>
          {
            handleBack &&
            <Box
              component="img"
              src={BackIco}
              sx={{
                pr: "13px",
                cursor: "pointer"
              }}
              onClick={handleBack}
            />
          }
          {dialogTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent} dividers sx={{ width: width }}>
          {children}
        </DialogContent>

        {isHaveButton &&
          <DialogActions sx={{ marginBottom: '20px', padding: "8px 24px", }}>
            <Stack
              sx={{
                alignItems: "flex-end",
              }}
            >
              <FormControl
                sx={{
                  mb: "12px"
                }}
                error={error !== ""}
              >
                <FormHelperText>{error}</FormHelperText>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {!singleButton && <CancelButton onClick={handleCancel} >{cancelText ?? t("app.button.cancel")}</CancelButton>}
                <ConfirmButton onClick={handleConfirm} sx={{ textTransform: "none", marginLeft: "20px" }} disabled={loading}>
                  {loading ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "white" }} /> : (confirmText ?? t("app.button.confirm"))}
                </ConfirmButton>
              </Box>
            </Stack>
          </DialogActions>}
      </Dialog>
    </>
  )
}

export default CommonDialogBox;