import { Button, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import SvgComponent from "../svgComponent";
import { appConfig } from "src/app-config";
import { useRouterLocation } from 'src/providers/RouterLocationProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

interface Props {
  handleClick?: () => void;
  text?: string;
  pathname?: string;
  hiddenLabel?: boolean;
  sx?: object;
}

const BackButton: FC<Props> = ({ handleClick, text, pathname, hiddenLabel = false, sx }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { prevLocation, } = useRouterLocation();
  // console.log('prevLocation:', prevLocation);
  const handleClickBack = useCallback(() => {

    if (handleClick) {
      handleClick();
    } else if (pathname) {
      navigate(pathname);
    } else if (prevLocation) {
      navigate(`${prevLocation.pathname}${prevLocation.search}`);
    } else {
      navigate(appConfig.paths.root);
    }
  }, [handleClick, prevLocation, navigate, pathname])

  return (
    <Button
      variant="text"
      onClick={handleClickBack}
      sx={{
        textTransform: "none",
        fontFamily: "Helvetica Neue",
        fontSize: "14px",
        fontWeight: "400",
        color: "#000000",
        padding: "6px 0",
        justifyContent: "flex-start",
        ...sx
      }}
    >
      <SvgComponent
        iconName="ico-back"
        style={{ width: "17px", height: "14px" }}
      />
      {!hiddenLabel && <>&nbsp;&nbsp;{text ? <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>{text}</Typography> : t("app.button.back")}</>}
    </Button>
  );
};
export default BackButton;
