import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  MemberPackage,
  SetMemberPackageProps,
  SubscribeProps,
  SubscribeResponse,
  SetSubscribePackageProps,
  GetSubscribePackageProps,
  getUserListProps,
  getUserListResponse,
} from "src/models/member";

export const memberApi = createApi({
  reducerPath: "member_api",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // 获取member套餐列表
      getMemberPackageList: builder.mutation<
        Result<MemberPackage[]>,
        undefined
      >({
        query: () => ({
          url: `/subscription/get_subscription_plan`,
          method: "POST",
        }),
        transformResponse: (res: Response<MemberPackage[]>) => new Value(res),
      }),
      //设置套餐
      setMemberPackage: builder.mutation<
        Result<undefined>,
        SetMemberPackageProps
      >({
        query: (SetMemberPackageProps) => ({
          url: `/subscription/set_subscription_amount`,
          method: "POST",
          body: SetMemberPackageProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      // 用户订阅
      setSubscribe: builder.mutation<Result<SubscribeResponse>, SubscribeProps>(
        {
          query: (props) => ({
            url: props.baseUrl
              ? `${props.baseUrl}/subscription/subscribe`
              : `/subscription/subscribe`,
            method: "POST",
            body: {
              amount: props.amount,
              message_id: props.message_id,
              sub_type: props.sub_type,
              subscribed_user_id: props.subscribed_user_id,
              type: props.type,
              source: props.source,
              is_continuous: props.is_continuous,
            },
          }),
          transformResponse: (res: Response<SubscribeResponse>) =>
            new Value(res),
        }
      ),
      // 设置订阅的套餐
      setSubscribePackage: builder.mutation<
        Result<undefined>,
        SetSubscribePackageProps
      >({
        query: (SetSubscribePackageProps) => ({
          url: `feedback`,
          method: "POST",
          body: SetSubscribePackageProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
      // 获取订阅的套餐
      getSubscribePackage: builder.mutation<
        Result<MemberPackage>,
        GetSubscribePackageProps
      >({
        query: (props) => ({
          url: props.baseUrl
            ? `${props.baseUrl}/subscription/get_subscription_amount`
            : `/subscription/get_subscription_amount`,
          method: "POST",
          body: {
            user_id: props.user_id,
          },
        }),
        transformResponse: (res: Response<MemberPackage>) => new Value(res),
      }),
      // 获取订阅者用户列表（包括我订阅的和订阅我的)
      getUserList: builder.mutation<
        Result<getUserListResponse>,
        getUserListProps
      >({
        query: (getUserListProps) => ({
          url: `/subscription/get_subscription_user`,
          method: "POST",
          body: getUserListProps,
        }),
        transformResponse: (res: Response<getUserListResponse>) =>
          new Value(res),
      }),
      getSubscriptionUser: builder.mutation<
        Result<getUserListResponse>,
        { userId: string }
      >({
        query: ({ userId }) => ({
          url: `/subscription/get_subscription_user`,
          method: "POST",
          body: {
            subscribed_user_id: userId,
          },
        }),
        transformResponse: (res: Response<getUserListResponse>) =>
          new Value(res),
      }),
      switchContinuousSubscribe: builder.mutation<
        Result<undefined>,
        {
          subscription_id: string;
          is_continuous: boolean;
          source?: string;
          baseUrl?: string;
        }
      >({
        query: ({ subscription_id, is_continuous, source, baseUrl }) => ({
          url: baseUrl
            ? `${baseUrl}/subscription/switch_continuous_subscribe`
            : `/subscription/switch_continuous_subscribe`,
          method: "POST",
          body: {
            subscription_id,
            is_continuous,
            source,
          },
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetMemberPackageListMutation,
  useSetMemberPackageMutation,
  useSetSubscribeMutation,
  useSetSubscribePackageMutation,
  useGetSubscribePackageMutation,
  useGetUserListMutation,
  useGetSubscriptionUserMutation,
  useSwitchContinuousSubscribeMutation,
} = memberApi;
