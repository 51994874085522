import { FC, useState, memo, ReactNode, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import clsx from "clsx";

import IcoHome from "src/images/main/home/ico-home.svg"
import IcoHomeSelected from "src/images/main/home/ico-home-selected.svg"
import IcoMarket from "src/images/main/home/ico-market.svg"
import IcoMarketSelected from "src/images/main/home/ico-market-selected.svg"
import IcoWorldView from "src/images/main/home/ico-worldview.svg"
import IcoWorldViewSelected from "src/images/main/home/ico-worldview-selected.svg"
import IcoAbout from "src/images/main/home/ico-about.svg"
import IcoSearch from "src/images/header/ico-search.svg"
import IcoChatSelected from "src/images/main/home/ico-chatroom-selected.svg"
import IcoChat from "src/images/chatroom/ico-chat.svg"
import IcoTravel from "src/images/travel/ico-travel.svg"
import IcoTravelSelected from "src/images/travel/ico-travel-selected.svg"
import { Link, useLocation } from "react-router-dom";
import { paths } from "src/app-config";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import SwitchPostPhotoMode from './SwitchPostPhotoMode';
import SwitchLanguage from './SwitchLanguage';

const useStyles = makeStyles()(() => {
  return {
    tab: {
      color: "#555555",
      background: "white",
      fontSize: "10px !important",
      fontWeight: '400',
      textTransform: "none",
      fontFamily: "Helvetica Neue !important",
      minWidth: "40px",
      width: `calc(100% / 5)`,
      margin: "0",
      paddingTop: '0px',
      paddingBottom: '0px',
      minHeight: "48px",
      "&.Mui-selected": {
        color: "black",
      }
    },
  };
});

const MainTabValue = {
  None: false,
  Home: 0,
  Market: 1,
  ChatroomList: 2,
  Worldview: 3,
  Search: 4,
  About: 5,
  Language: 6,
  Switch: 7,
  Travel: 8
};

interface Props {
  hasUnreadChatroomMessage: boolean
}

const MobileHeader = ({ hasUnreadChatroomMessage }: Props) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState<boolean | number>(MainTabValue.None);

  useEffect(() => {
    const pathSegments = "/" + location.pathname.split("/")[1];
    if (location.pathname === paths.root) {
      setTabValue(MainTabValue.Home);
    } else if (
      location.pathname.startsWith(paths.worldview)
    ) {
      setTabValue(MainTabValue.Worldview);
    } else if (pathSegments === paths.market) {
      setTabValue(MainTabValue.Market);
    } else if (pathSegments === paths.chatroomList) {
      setTabValue(MainTabValue.ChatroomList);
    } else if (pathSegments === paths.travel) {
      setTabValue(MainTabValue.Travel);
    }
  }, [location]);

  return (
    <Box
      sx={{
        width: '100%',
        borderTop: 'solid 1px #eee'
      }}
    >
      <Tabs
        value={tabValue}
        // variant="scrollable"
        // scrollButtons
        // allowScrollButtonsMobile
        sx={{ height: '54px', maxWidth: '480px', alignItems: 'center', margin: '0 auto' }}
      >
        <Tab
          icon={<img src={tabValue === MainTabValue.Home ? IcoHomeSelected : IcoHome} alt="home" />}
          className={classes.tab}
          component={Link}
          to={paths.root}
          aria-label={t("app.home.for_you")}
          value={MainTabValue.Home}
          label={t("app.home.for_you")}
        />
        <Tab
          icon={<img src={tabValue === MainTabValue.Travel ? IcoTravelSelected : IcoTravel} alt="travel" />}
          className={classes.tab}
          component={Link}
          to={paths.travel}
          aria-label={t("app.home.travel")}
          value={MainTabValue.Travel}
          label={t("app.home.travel")}
        />
        <Tab
          icon={<img src={tabValue === MainTabValue.ChatroomList ? IcoChatSelected : IcoChat} alt="chatroom" />}
          sx={{
            position: 'relative',
            '&.show-badge::before': {
              content: '""',
              position: 'absolute',
              top: '0px',
              right: i18n.language === 'zh-CN' ? '13px' : "22px",
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              background: 'rgba(239, 62, 62, 1)',
            }
          }}
          className={clsx(classes.tab, { "show-badge": hasUnreadChatroomMessage && tabValue != MainTabValue.ChatroomList })}
          component={Link}
          to={paths.chatroomList}
          aria-label={t("app.home.chatroom")}
          value={MainTabValue.ChatroomList}
          label={t("app.home.chatroom")}
        />
        <Tab
          icon={<img src={tabValue === MainTabValue.Market ? IcoMarketSelected : IcoMarket} alt="market" />}
          className={classes.tab}
          component={Link}
          to={paths.market}
          aria-label={t("app.home.market")}
          value={MainTabValue.Market}
          label={t("app.home.market")}
        />
        <Tab
          icon={<img src={tabValue === MainTabValue.Worldview ? IcoWorldViewSelected : IcoWorldView} alt="worldview" />}
          className={classes.tab}
          component={Link}
          to={paths.worldview}
          aria-label={t("app.home.world-view")}
          value={MainTabValue.Worldview}
          label={t("app.home.world-view")}
        />
        {/* <Tab
          icon={<img src={IcoSearch} alt="search" />}
          className={classes.tab}
          to={paths.searchHistory}
          component={Link}
          aria-label={t("app.header.mobile.search")}
          value={MainTabValue.Search}
          label={t("app.header.mobile.search")}
        />
        <Tab
          icon={<img src={IcoAbout} alt="about" />}
          className={classes.tab}
          component={Link}
          to={paths.aboutUs}
          value={MainTabValue.About}
          label={t("app.header.about")}
        />
        <IconSwitchLanguage />
        <IconSwitchPostPhotoMode /> */}
      </Tabs>
    </Box>
  )
}

interface IconComponentProps {
  children: ReactNode
}

const IconComponent: FC<IconComponentProps> = ({ children }) => {
  return (
    <Box
      sx={{
        width: '56px',
        minHeight: "48px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        flexDirection: 'column',
        fontSize: '10px',
        color: '#555555',
        fontWeight: '400',
        lineHeight: '12.5px'
      }}
    >
      {children}
    </Box>
  )
}


const IconSwitchPostPhotoMode: FC = () => {
  const { t } = useTranslation();
  return <IconComponent>
    <SwitchPostPhotoMode />
    {t("app.header.switch")}
  </IconComponent>
}

const IconSwitchLanguage: FC = () => {
  const { t } = useTranslation();
  return <IconComponent>
    <SwitchLanguage />
    {t("app.header.language")}
  </IconComponent>
}

// const MobileHeader: FC = () => {
//   const [value, setValue] = useState(0);

//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     setValue(newValue);
//   };

//   return (
//     <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>
//       <CustomTabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons
//         allowScrollButtonsMobile
//         aria-label="scrollable auto tabs example"
//       >
//         <Tab label="Item One" />
//         <Tab label="Item Two" />
//         <Tab label="Item Three" />
//         <Tab label="Item Four" />
//         <Tab label="Item Five" />
//         <Tab label="Item Six" />
//         <Tab label="Item Seven" />
//       </CustomTabs>
//     </Box>
//   );
// }

export default memo(MobileHeader);