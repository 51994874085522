import { Box, CircularProgress, MenuItem, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ConfirmButton from "src/components/styled/ConfirmButton";
import { makeStyles } from "tss-react/esm/mui";
import { Controller, useForm } from "react-hook-form";
// import { useForgetPasswordResetPasswordMutation, useForgetPasswordSendCodeMutation } from "src/service/api/auth-api";
// import { useSelector } from "src/tools/toolkit/store";
import genRandom, { ErrorCode, ReservedUser, SIGN_UP_RESERVED_USER_IDS_KEY } from "src/tools/utils/auth";
import OutlineButton from "src/components/styled/OutlineButton";
// import ConfirmDialog from "src/components/dialog/confirm/ConfirmDialog";
import { EMAIL_FORMAT, PASSWORD_FORMAT, US_PHONE_FORMAT, CN_PHONE_FORMAT, USERID_FORMAT } from "src/tools/utils/stringUtil";
import CommonTextField from "src/components/styled/CommonTextField";
import { isCN } from "src/tools/utils/appUtil";
import IcoPrompt from "src/images/main/nearby/ico-prompt.svg"
import { useCheckUserIDMutation, useSignUpSendCodeMutation, useSignUpVerifyCodeMutation } from "src/service/api/signup-api";
// import { Result } from "src/models/common";
import * as crypto from "crypto";
import * as ECKey from 'ec-key';
import IcoEyeOpen from "src/images/login/ico-eye-open.svg"
import IcoEyeClose from "src/images/login/ico-eye-close.svg"
import { getStoredValue, setStoreValue } from "src/tools/utils/storage"

const useStyles = makeStyles()((theme) => {
  return {
    rulesTitle: {
      color: "#555",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 700,
      marginTop: "15px",
      lineHeight: "24px"
    },
    rulesText: {
      color: "#555",
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px"
    },
    forgotPassword: {
      color: "#000",
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        color: "#555"
      }
    },
    promptText: {
      color: "#555",
      fontFamily: "Helvetica Neue",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px"
    }
  }
})

interface FormEditPasswordInputs {
  userId: string;
  phone: string;
  email: string;
  password: string;
}
interface VerificationCodeInputs {
  verifyCode: string;
}
interface Props {
  onSignUpSuccess: (userID: string) => void;
}

interface SignUpProps {
  userId:string;
  uuid:string;
  pubkey?:string;
  signature:string;
  clientSalt:string;
  passwordHashInfo:string;
  phone: string | undefined;
  email:string;
}

const SignUpForm: FC<Props> = ({ onSignUpSuccess }) => {
  // const theme = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [verifyType, setVerifyType] = useState<"phone" | "email">("phone");
  const [country, setCountry] = useState<"1" | "86">(isCN ? "86" : "1");
  const [countdown, setCountdown] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const formFunctions1 = useForm<FormEditPasswordInputs>({
    reValidateMode: "onBlur"
  });

  const formFunctions2 = useForm<VerificationCodeInputs>({
    reValidateMode: "onBlur"
  });

  const [checkSignUpUserID, { isLoading: loading1 }] = useCheckUserIDMutation()
  const [signUpSendVerifyCode, { isLoading: loading2 }] = useSignUpSendCodeMutation()
  const [signUpVerifyCode, { isLoading: loading3 }] = useSignUpVerifyCodeMutation()

  useEffect(() => {
    formFunctions1.reset();
    formFunctions2.reset();
  }, [])

  useEffect(() => {
    const currentCount = countdown;
    const intervalID = setInterval(() => {
      if (currentCount > 0) {
        setCountdown(count => count - 1)
      }
    }, 1000)
    return () => clearInterval(intervalID);
  }, [countdown])

  const handleVerifyTypeChange = (e) => {
    setVerifyType(e.target.value)
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value)
  }

  const handleSendCodeClick = (evt: FormEditPasswordInputs) => {
    checkUserId(evt);
  }

  // 1.check user id valid
  const checkUserId = async (evt: FormEditPasswordInputs) => {
    const myID = evt.userId;
    checkSignUpUserID({ userId: myID })
      .unwrap()
      .then((res) => {
        if (res && !res.isFailed) {
          const uuid = res?.result?.uuid;
          if (uuid) {
            // save reserved user ID to local storage
            const reservedUsersString = localStorage.getItem(SIGN_UP_RESERVED_USER_IDS_KEY);
            const userIDs: ReservedUser[] = reservedUsersString ? JSON.parse(reservedUsersString) : [];
            const newArray = userIDs?.concat({ userId: myID, uuid: uuid });
            localStorage.setItem(SIGN_UP_RESERVED_USER_IDS_KEY, JSON.stringify(newArray));
          }
          sendVerificationCode(evt, uuid)
        } else {
          if (res.resultCode === ErrorCode.SIGN_UP_USER_ID_EXIST) {
            // find reserved user ID and uuid
            const reservedUsersString = localStorage.getItem(SIGN_UP_RESERVED_USER_IDS_KEY);
            const userIDs: ReservedUser[] = reservedUsersString ? JSON.parse(reservedUsersString) : [];
            const findUser = userIDs?.find(item => item.userId === myID);
            if (findUser) {
              sendVerificationCode(evt, findUser.uuid);
            } else {
              enqueueSnackbar(t('app.login.sign-up-user-id-exist', { userID: myID }), { variant: 'error' })
            }
          } else {
            enqueueSnackbar(res?.message, { variant: 'error' })
          }
        }
      })
      .catch((e) => { console.log(e) })
  }

  // 2. send verify code
  const sendVerificationCode = async (evt: FormEditPasswordInputs, uuid: string) => {
    const password = evt.password;
    const myID = evt.userId;
    const clientSalt: string = await genRandom();
    const passwordHashInfo: string = crypto
      .createHash('sha256')
      .update(password + clientSalt)
      .digest('base64');
    const key = ECKey.createECKey('prime256v1');
    const pubkey = key.asPublicECKey().toString('spki');

    const msg = Buffer.from(myID).toString('base64') + passwordHashInfo;
    const signature = key.createSign('SHA256').update(msg).sign('base64');
    const publicKey = getStoredValue(myID) ?? "";

    let param: SignUpProps={
      userId: myID,
      uuid: uuid,
      signature: signature,
      clientSalt: clientSalt,
      passwordHashInfo: passwordHashInfo,
      phone: evt.phone ? country + "-" + evt.phone : undefined,
      email: evt.email
    }

    if (publicKey){
      param.pubkey = publicKey;
    }else{
      param.pubkey = pubkey;
      setStoreValue(myID, pubkey);
    }

    signUpSendVerifyCode(param)
      .unwrap()
      .then((res) => {
        if (res && !res?.isFailed) {
          enqueueSnackbar(t("app.login.send-code-success"), { variant: 'success' })
          // start countdown
          setCountdown(60);
        } else {
          enqueueSnackbar(res?.message, { variant: 'error' })
        }
      })
      .catch((e) => { console.log(e) })
  }


  // 3. verify code
  const handleClickSignUp = async (evt: VerificationCodeInputs) => {
    const isValid = await formFunctions1.trigger();
    if (!isValid) return;
    const userId = formFunctions1.watch("userId");
    const verificationCode = evt.verifyCode.trim().replace(/\s+/g, '');
    signUpVerifyCode({
      userId: userId,
      verifyCode: verificationCode,
    })
      .unwrap()
      .then((res) => {
        if (!res?.isFailed) {
          onSignUpSuccess(userId);

          // remove reserved user ID from local storage
          const reservedUsersString = localStorage.getItem(SIGN_UP_RESERVED_USER_IDS_KEY);
          const userIDs: ReservedUser[] = reservedUsersString ? JSON.parse(reservedUsersString) : [];
          const newArray = userIDs?.filter(item => item.userId !== userId);
          localStorage.setItem(SIGN_UP_RESERVED_USER_IDS_KEY, JSON.stringify(newArray));
        } else if (res?.resultCode === ErrorCode.SIGN_UP_VERIFY_CODE_INCORRECT) {
          enqueueSnackbar(t("app.login.verification-code-error"), { variant: 'error' })
        } else {
          const errorMessage = res?.message || "Sign up failed"
          enqueueSnackbar(errorMessage, { variant: "error" })
        }
      })
      .catch((error) => {
        const errorMessage = error?.data?.message || "Sign up failed"
        enqueueSnackbar(errorMessage, { variant: "error" })
      })
  }

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
        <Box sx={{ width: "100%" }}>

          <Controller
            control={formFunctions1.control}
            name="userId"
            rules={{
              required: { value: true, message: t("app.settings.account.user-id-is-required") },
              minLength: { value: 5, message: t("app.settings.account.user-id-min-length") },
              maxLength: { value: 20, message: t("app.settings.account.user-id-max-length") },
              pattern: { value: USERID_FORMAT, message: t("app.settings.account.user-id-not-valid") }
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (

              <CommonTextField
                {...field}
                sx={{ marginBottom: "24px" }}
                type="text"
                fullWidth
                size='small'
                placeholder={t("app.login.username")}
                error={error !== undefined}
                helperText={error ? error.message : ""}
              />
            )}
          />

          <Box sx={{ position: "relative" }}>
            <Controller
              control={formFunctions1.control}
              name="password"
              rules={{
                required: t("app.settings.account.password-is-required"),
                minLength: { value: 6, message: t("app.settings.account.password-min-length") },
                maxLength: { value: 50, message: t("app.settings.account.password-max-length") },
                pattern: { value: PASSWORD_FORMAT, message: t("app.settings.account.password-format-not-valid") }
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (

                <CommonTextField
                  {...field}
                  sx={{ marginBottom: "24px", height: 'unset' }}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  size='small'
                  placeholder={t("app.login.password")}
                  error={error !== undefined}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ position: "absolute", right: "5px", top: "9px" }}>
              <img alt="show_password" src={showPassword ? IcoEyeOpen : IcoEyeClose} style={{ width: "16px", height: "12px" }} />
            </IconButton>
          </Box>

          <CommonTextField
            sx={{ marginBottom: "24px" }}
            fullWidth
            size='small'
            select
            value={verifyType}
            onChange={handleVerifyTypeChange}
          >
            <MenuItem value="phone">{t("app.login.phone")}</MenuItem>
            <MenuItem value="email">{t("app.login.email")}</MenuItem>
          </CommonTextField>

          {verifyType === "phone" &&
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ width: "108px", marginRight: "12px" }}>

                <CommonTextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  size='small'
                  select
                  value={country}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="1">+1</MenuItem>
                  <MenuItem value="86">+86</MenuItem>
                </CommonTextField>

              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Controller
                  control={formFunctions1.control}
                  name="phone"
                  rules={{
                    required: { value: verifyType === "phone", message: t("app.login.phone-number-required") },
                    pattern: {
                      value: country === "1" ? US_PHONE_FORMAT : CN_PHONE_FORMAT,
                      message: t("app.login.phone-number-format-not-valid")
                    }
                  }}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (

                    <CommonTextField
                      {...field}
                      sx={{ marginBottom: "24px" }}
                      type="number"
                      fullWidth
                      size='small'
                      placeholder={t("app.login.phone-number")}
                      error={error !== undefined}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </Box>
            </Box>
          }
          {verifyType === "email" &&
            <Controller
              control={formFunctions1.control}
              name="email"
              rules={{
                required: { value: verifyType === "email", message: t("app.login.email-address-required") },
                pattern: { value: EMAIL_FORMAT, message: t("app.login.email-format-not-valid") }
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (

                <CommonTextField
                  {...field}
                  sx={{ marginBottom: "24px" }}
                  type="email"
                  fullWidth
                  size='small'
                  placeholder={t("app.login.email-address")}
                  error={error !== undefined}
                  helperText={error ? error.message : ""}
                />
              )}
            />
          }
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

            <Controller
              control={formFunctions2.control}
              name="verifyCode"
              rules={{
                required: t("app.settings.account.verification-code-is-required")
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (

                <CommonTextField
                  {...field}
                  fullWidth
                  type="text"
                  size='small'
                  placeholder={t("app.settings.account.verification-code")}
                  error={error !== undefined}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <OutlineButton sx={{ marginLeft: "12px" }} onClick={formFunctions1.handleSubmit(handleSendCodeClick)} disabled={countdown > 0 || loading1 || loading2}>
              {(loading1 || loading2) ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "#3A92F9" }} /> : (countdown > 0 ? `${countdown} s` : t("app.button.send"))}
            </OutlineButton>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", margin: "19px 0" }}>
            <img src={IcoPrompt} alt="prompt" style={{ marginRight: "6px" }} />
            <Box className={classes.promptText}>{t("app.login.forget-and-reset-password-desc")}</Box>
          </Box>
        </Box>

        <ConfirmButton sx={{ width: "100%" }} onClick={formFunctions2.handleSubmit(handleClickSignUp)}>
          {loading3 ? <CircularProgress sx={{ width: "15px !important", height: "15px !important", color: "white" }} /> : t("app.login.sign-up")}
        </ConfirmButton>

        <Box className={classes.rulesTitle}>{t("app.settings.account.password-rules")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-1")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-2")}</Box>
        <Box className={classes.rulesText}>•&nbsp;{t("app.settings.account.password-rules-3")}</Box>

      </Box>
    </>
  )

}

export default SignUpForm;