import { Box, Dialog, IconButton } from "@mui/material";
import { FC, useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  url: string;
  open: boolean;
  handleClose: () => void;
  urlInfo?: {
    width?: number;
    height?: number;
  };
}

const PreviewPicture: FC<Props> = ({ url, open, handleClose, urlInfo }) => {
  const [imgStyle, setImgStyle] = useState<any>({});
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [scale, setScale] = useState(1);
  const [transformOrigin, setTransformOrigin] =
    useState<string>("center center");

  const handleImgStyle = useCallback((width: number, height: number) => {
    if (height > width) {
      setImgStyle({
        height: "100vh",
        width: "auto",
      });
    } else {
      setImgStyle({
        height: "auto",
        width: "100vw",
      });
    }
  }, []);

  useEffect(() => {
    if (urlInfo?.width && urlInfo?.height) {
      handleImgStyle(urlInfo?.width, urlInfo?.height);
    } else {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const { width, height } = img;
        handleImgStyle(width, height);
      };
    }
  }, [url, handleImgStyle, urlInfo?.width, urlInfo?.height]);

  const handleClickImg = (e: React.MouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (isZoomed) {
      setIsZoomed(false);
      setScale(1);
      setTransformOrigin("center center");
    } else {
      setIsZoomed(true);
      setScale(2);
      setTransformOrigin(
        `${(x / rect.width) * 100}% ${(y / rect.height) * 100}%`
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        "& .MuiDialog-paper": {
          maxWidth: "100vw",
          maxHeight: "100vh",
          margin: "auto !important",
          borderRadius: "",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Box
        component="img"
        src={url}
        sx={{
          ...imgStyle,
          maxWidth: isZoomed ? "none" : "100vw",
          maxHeight: isZoomed ? "none" : "100vh",
          objectFit: "cover",
          cursor: isZoomed ? "zoom-out" : "zoom-in",
          transition: "all 0.3s ease",
          transform: isZoomed ? `scale(${scale})` : "scale(1)",
          transformOrigin: transformOrigin,
        }}
        onClick={handleClickImg}
      />
      {open && (
        <IconButton
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 8,
            right: 8,
            color: "#fff",
            zIndex: 1301,
            background: "#333333",
            borderRadius: "50%",
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Dialog>
  );
};

export default PreviewPicture;
