import { FC, useState, useRef } from "react";
import { Box, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "src/tools/toolkit/store";
import { useDispatch } from "react-redux";
import { getHash } from "src/tools/utils/stringUtil";
import { useUploadFileMutation } from "src/service/api/upload-file-api";
import { uuid } from "src/tools/utils/auth";
import { showSignInUpDialog } from "src/service/redux/signinup-slice";
import PreviewPicture from "src/pages/travel/travelDetailPage/PreviewPicture";
import icoClose from "src/images/travel/ico-close.svg";

interface Props {
  handelAddMediaUrl: (url: string) => void;
  handleRemoveMediaUrl: (url: string) => void;
}

const SelectFileBox: FC<Props> = ({
  handelAddMediaUrl,
  handleRemoveMediaUrl,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadImgFile] = useUploadFileMutation();
  const { credential } = useSelector((state) => state.app);
  const [media, setMedia] = useState<string>("");
  const [imgOpen, setImgOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");

  const handleSelect = (e) => {
    e.stopPropagation();
    if (!credential?.user_id) {
      dispatch(showSignInUpDialog());
      return;
    }
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };
  const getFileExtension = (fileName: string) => {
    return fileName.split(".").pop() || "jpg"; // 默认为'jpg'
  };

  const handleMedia = (url: string) => {
    setMedia(url);
    handelAddMediaUrl(url);
    setLoading(false);
  };

  const handleReselectCover = () => {
    setMedia("");
    handleRemoveMediaUrl(media);
  };

  const uploadFile = async (
    fileObj: File,
    fileName: string,
    fileType: string
  ) => {
    const fileHash = await getHash(fileObj);
    const fileExtension = getFileExtension(fileName);
    const uniqueFileName = `${uuid()}.${fileExtension}`;
    const response = await uploadImgFile({
      user_id: credential?.user_id || "",
      file_name: uniqueFileName,
      file_type: fileType,
      hash: fileHash,
    }).unwrap();
    if (response && !response.isFailed && response.result) {
      const pictureUrl = response.result.public;
      const uploadUrl = response?.result.upload;
      if (uploadUrl) {
        try {
          const response2 = await fetch(response?.result.upload, {
            method: "PUT",
            body: fileObj,
            headers: {
              "Content-Type": fileObj.type,
            },
          });
          if (response2.status === 200) {
            handleMedia(pictureUrl);
          } else {
            setLoading(false);
            enqueueSnackbar("Upload File failed!", { variant: "error" });
          }
        } catch (e) {
          setLoading(false);
          enqueueSnackbar("Upload File failed!", { variant: "error" });
        }
      } else if (pictureUrl) {
        handleMedia(pictureUrl);
      } else {
        setLoading(false);
        enqueueSnackbar("No image url!", { variant: "error" });
      }
    } else {
      setLoading(false);
      enqueueSnackbar("Upload File failed!", { variant: "error" });
    }
  };
  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    setLoading(true);
    const fileObj: File = event.target.files[0];
    if (fileObj) {
      event.target.value = "";
      uploadFile(fileObj, fileObj.name, fileObj.type);
    }
  };
  const handlePreviewPicture = () => {
    const url = media;
    if (url) {
      setImgOpen(true);
      setUrl(url);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          width: "80px",
          height: "80px",
          border: "1px dashed #9CA3AD",
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          borderRadius: "8px",
          marginRight: "10px",
          marginBottom: "10px",
        }}
      >
        {loading && !media && <CircularProgress />}
        {!loading && !media && (
          <Box
            onClick={handleSelect}
            sx={{
              cursor: "pointer",
              position: "relative",
              width: "32px",
              height: "32px",
              "&::before, &::after": {
                content: '""',
                position: "absolute",
                backgroundColor: "#9CA3AD",
              },
              "&::before": {
                width: "2px",
                height: "100%",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              },
              "&::after": {
                width: "100%",
                height: "2px",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
              },
            }}
          />
        )}
        {media && !loading && (
          <Box>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              component="img"
              src={media}
              onClick={handlePreviewPicture}
            />
          </Box>
        )}
        {media && !loading && (
          <Box
            component="img"
            src={icoClose}
            sx={{
              position: "absolute",
              right: "3px",
              top: "3px",
              cursor: "pointer",
            }}
            onClick={handleReselectCover}
          />
        )}
      </Box>
      <input
        ref={fileInputRef}
        title="Select picture"
        onChange={(e) => onFileChange(e)}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onClick={(e) => e.stopPropagation()}
      />
      {imgOpen && (
        <PreviewPicture
          open={imgOpen}
          url={url}
          handleClose={() => setImgOpen(false)}
        />
      )}
    </Box>
  );
};

export default SelectFileBox;
