import { RefObject } from 'react';
import { getDateFormat1 } from "./timeUtil";
import { PaymentCurrency } from "src/models/payment";
import { appConfig } from "src/app-config";
import {
  PostEntity,
  UriEntity,
} from "src/models/post";
import { isAdmin, isAdminPost } from "src/tools/utils/auth";
import { getStoredValue } from "src/tools/utils/storage";
import { UserProfile } from "src/models/user"
import { isValidJSON } from "src/tools/utils/appUtil";

interface DateGroup {
  date: string;
  list: PostEntity[];
}
export const getDateGroupList = (list: PostEntity[]): DateGroup[] => {
  const resultList: DateGroup[] = [];
  if (!list || list.length === 0) {
    return [];
  }
  list.forEach((item) => {
    const dateStr = getDateFormat1(item.timestamp * 1000);
    const findDateGroup = resultList.find(
      (dateObj) => dateObj.date === dateStr
    );
    if (findDateGroup) {
      findDateGroup.list.push(item);
    } else {
      const newDateGroupObj: DateGroup = { date: dateStr, list: [item] };
      resultList.push(newDateGroupObj);
    }
  });
  return resultList;
};


interface DatePostItem {
  date?: string;
  post: PostEntity;
  index: number;
}

interface DateGroupIndex {
  date: string;
  list: { post: PostEntity, index: number }[];
}

export const getDatePostList = (list: PostEntity[]): DatePostItem[] => {
  const result: DatePostItem[] = [];
  if (!list || list.length === 0) {
    return result;
  }

  const dateGroupList: DateGroupIndex[] = [];
  list.forEach((item, index) => {
    const dateStr = getDateFormat1(item.timestamp * 1000);
    const findDateGroup = dateGroupList.find(
      (dateObj) => dateObj.date === dateStr
    );
    if (findDateGroup) {
      findDateGroup.list.push({ post: item, index });
    } else {
      const newDateGroupObj: DateGroupIndex = { date: dateStr, list: [{ post: item, index }] };
      dateGroupList.push(newDateGroupObj);
    }
  });

  dateGroupList.forEach(group => {
    let first = true;
    group.list.forEach(item => {
      if (first) {
        result.push({
          date: group.date,
          post: item.post,
          index: item.index
        });
        first = false;
      } else {
        result.push({
          post: item.post,
          index: item.index
        })
      }
    })
  })

  return result;
}


export const computeMaxHeight = (theme, ref?: RefObject<HTMLElement>) => {
  let maxHeight = "calc(680px * 1.2)";
  if (window.innerWidth <= theme.breakpoints.values.sm) {
    maxHeight = "calc((100vw - 24px) * 1.2)";
  } else if (
    window.innerWidth > theme.breakpoints.values.sm &&
    window.innerWidth < theme.breakpoints.values.xl
  ) {
    let cardWidth = window.innerWidth * 0.4;
    if (cardWidth > 680) cardWidth = 680;
    if (cardWidth < 500) cardWidth = 500;
    if (window.innerHeight - 200 >= cardWidth * 1.2) {
      maxHeight = `calc(${cardWidth}px * 1.2)`;
    } else {
      maxHeight = "calc(100vh - 200px)";
    }
  } else if (window.innerWidth >= theme.breakpoints.values.xl) {
    maxHeight = "calc(680px * 1.2)";
  }

  if (ref && ref.current) {
    ref.current.style.maxHeight = maxHeight;
  } else {
    return maxHeight;
  }
};

export const calculatePriceStr = (price, currency, t) => {
  if (!price) return "";
  let priceStr = price?.toString();
  let decimalIndex = price?.toString()?.indexOf(".");
  if (decimalIndex !== -1) {
    // 如果小数点后位数超过两位，则进行截断
    if (priceStr.length - decimalIndex > 3) {
      price = price?.toFixed(2);
    }
  }
  if (currency === PaymentCurrency.COIN || !currency) {
    return price === 0 || price === 1
      ? `${t("app.post.coin", { price: price })}`
      : `${t("app.post.coins", { price: price })}`;
  } else if (currency === PaymentCurrency.USD) {
    return `${t("app.post.USD", { price: price })}`;
  } else if (currency === PaymentCurrency.CNY) {
    return `${t("app.post.CNY", { price: price })}`;
  }
};

export enum InViewPortAdjustType {
  Percent,
  Fixed
}

export interface InViewPortOptions {
  type: InViewPortAdjustType;
  value: number;
}

export interface ViewPortValue {
  fixed?: number;
  percent?: number;
}

export const isInViewport = (element: Element, topViewPortValue?: ViewPortValue, bottomViewPortValue?: ViewPortValue) => {
  const rect = element.getBoundingClientRect();
  let topOff = 0;
  let bottomOff = 0;

  if (topViewPortValue) {
    if (topViewPortValue.fixed) {
      topOff += topViewPortValue.fixed;
    }
    if (topViewPortValue.percent) {
      topOff += rect.height * topViewPortValue.percent;
    }
  }


  if (bottomViewPortValue) {
    if (bottomViewPortValue.fixed) {
      bottomOff += bottomViewPortValue.fixed;
    }
    if (bottomViewPortValue.percent) {
      bottomOff += rect.height * bottomViewPortValue.percent;
    }
  }


  return (
    rect.top >= topOff &&
    rect.left >= 0 &&
    rect.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) + bottomOff &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};


export const goToUserProfile = (userID: string) => {
  if (!userID) return;
  window.open(
    `${appConfig.paths.user}?id=${userID}`,
    "_blank"
  );
}

export const isHide = (post:PostEntity, item: UriEntity) => {
  const userInfoJson = getStoredValue("app-state") || ""
  if (!isValidJSON(userInfoJson)) return false;
  const credential = JSON.parse(userInfoJson).credential;
  // 管理员查看所有post列表不需要遮罩
  if (isAdminPost() && isAdmin(credential)) {
    return false;
  }
  // 查看自己的帖子不需要遮罩
  if (post?.owner?.user_id === credential?.user_id) {
    return false;
  }
  // 此帖子设置了付费观看
  if (post?.message_content?.pay_to_watch) {
    // 如果已经订阅该用户，则不需要遮罩
    if (post?.is_subscribed_user) {
      return false;
    }
    // 如果购买了这个帖子，也不需要遮罩
    if (post?.is_subscribed_post) {
      return false;
    }
    return Boolean(!item?.pay_to_watch_free);
  } else {
    return false;
  }
};