import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
    showFeedbackDialog: boolean,
    showFeedbackAlert: boolean,
}

const initState: AuthState = {
    showFeedbackDialog: false,
    showFeedbackAlert: false,
}


const authSlice = createSlice({
    name: 'feedback',
    initialState: initState,
    reducers: {
        setShowFeedbackDialog(state) {
            state.showFeedbackDialog = true;
        },
        setCloseFeedbackDialog(state) {
            state.showFeedbackDialog = false;
        },
        setShowFeedbackAlert(state) {
            state.showFeedbackAlert = true;
        },
        setCloseFeedbackAlert(state) {
            state.showFeedbackAlert = false;
        },
    }
})


export const { setShowFeedbackDialog, setCloseFeedbackDialog, setShowFeedbackAlert, setCloseFeedbackAlert } = authSlice.actions

export default authSlice.reducer