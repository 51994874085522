import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth } from "../../tools/toolkit/helper";
import {
  GetFeedbackMenuListProps,
  GetFeedbackMenuListResponse,
  CreateFeedbackProps,
  GetFeedbackListProps,
  deleteFeedbackProps,
    GetFeedbackListResponse
} from "src/models/feedback";

export const feedbackApi = createApi({
  reducerPath: "feedback_api",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      // 获取feedback清单列表
      getFeedbackMenuList: builder.mutation<
        Result<GetFeedbackMenuListResponse>,
        GetFeedbackMenuListProps
      >({
        query: (GetFeedbackMenuListProps) => ({
          url: `feedback`,
          method: "POST",
          body: GetFeedbackMenuListProps,
        }),
        transformResponse: (res: Response<GetFeedbackMenuListResponse>) =>
          new Value(res),
      }),

      // 创建一个feedback
      createFeedback: builder.mutation<Result<undefined>, CreateFeedbackProps>({
        query: (CreateFeedbackProps) => ({
          url: `feedback`,
          method: "POST",
          body: CreateFeedbackProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),

      // 获取自己的feedback列表
      getFeedbackList: builder.mutation<
          Result<GetFeedbackListResponse>,
        GetFeedbackListProps
      >({
        query: (GetFeedbackListProps) => ({
          url: `feedback`,
          method: "POST",
          body: GetFeedbackListProps,
        }),
          transformResponse: (res: Response<GetFeedbackListResponse>) => new Value(res),
      }),

      // 删除
      deleteFeedback: builder.mutation<Result<undefined>, deleteFeedbackProps>({
        query: (deleteFeedbackProps) => ({
          url: `feedback`,
          method: "POST",
          body: deleteFeedbackProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
    };
  },
});

export const {
  useGetFeedbackMenuListMutation,
  useCreateFeedbackMutation,
  useGetFeedbackListMutation,
  useDeleteFeedbackMutation,
} = feedbackApi;
