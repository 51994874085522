import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseAdminQueryWithReAuth } from "../../tools/toolkit/helper";
import { NewsDetailResponseData, NewsListResponseData } from "src/models/news";

export const adminNewsApi = createApi({
  reducerPath: "adminNews",
  baseQuery: baseAdminQueryWithReAuth,
  endpoints(builder) {
    return {
      adminCreateNews: builder.mutation<
        Result<NewsDetailResponseData>,
        {
          title: string;
          content: string;
          image: string;
          users: string;
          introduction: string;
          popup: boolean;
          start_time: string; // 秒级
          end_time: string;
        }
      >({
        query: ({
          title,
          content,
          image,
          users,
          introduction,
          popup,
          start_time,
          end_time,
        }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "createannouncement",
            title,
            start_time,
            end_time,
            content,
            image,
            introduction,
            popup,
            scope: users,
            scope_value: users,
          },
        }),
        transformResponse: (res: Response<NewsDetailResponseData>) =>
          new Value(res),
      }),
      adminEditNews: builder.mutation<
        Result<any>,
        {
          id: string;
          title: string;
          content: string;
          image: string;
          users: string;
          introduction: string;
          popup: boolean;
          start_time: string; // 秒级
          end_time: string;
        }
      >({
        query: ({
          id,
          title,
          content,
          image,
          users,
          introduction,
          popup,
          start_time,
          end_time,
        }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "editannouncement",
            announcement_id: id,
            title,
            start_time,
            end_time,
            content,
            image,
            introduction,
            popup,
            scope: users,
            scope_value: users,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      adminUpdateNewLink: builder.mutation<
        Result<any>,
        {
          id: string;
          link: string;
        }
      >({
        query: ({ id, link }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "editannouncement",
            announcement_id: id,
            link,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      adminGetNewsList: builder.mutation<
        Result<NewsListResponseData>,
        { pageSize: number; startKey: string }
      >({
        query: ({ pageSize, startKey }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "getallannouncementlist",
            pagesize: pageSize,
            startkey: startKey,
          },
        }),
        transformResponse: (res: Response<NewsListResponseData>) =>
          new Value(res),
      }),

      adminDeleteNews: builder.mutation<Result<any>, { id: string }>({
        query: ({ id }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "deleteannouncement",
            announcement_id: id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),

      adminPublishNews: builder.mutation<Result<any>, { id: string }>({
        query: ({ id }) => ({
          url: `announcement`,
          method: "POST",
          body: {
            operation: "publishnotification",
            announcement_id: id,
            release_time: Math.floor(new Date().getTime() / 1000).toString(),
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useAdminCreateNewsMutation,
  useAdminEditNewsMutation,
  useAdminUpdateNewLinkMutation,
  useAdminGetNewsListMutation,
  useAdminDeleteNewsMutation,
  useAdminPublishNewsMutation,
} = adminNewsApi;
