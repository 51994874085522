import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { Header } from './header'
// import { WEBSITE_PRODUCTION_CN, isWeixinBrowser } from 'src/tools/utils/appUtil';
import { Outlet, useSearchParams } from "react-router-dom";
import { PC_HEADER_HEIGHT } from './header/Header';
import OpenAppBox from './openApp/OpenAppBox';
import { hideOpenAppSet } from 'src/app-config';
// import { isWebview } from 'src/tools/utils/deviceUtil';
// import ConfirmButton from 'src/components/styled/ConfirmButton';
// import logo from 'src/images/logo/logo72.png'
// import { useTranslation } from 'react-i18next';
import { OpenAppBoxHeight } from './openApp/OpenAppBox';
import RecommendIcon from "src/components/recommendIcon/RecommendIcon"
import { onTableOrMobile } from 'src/tools/utils/appUtil';
import useRefreshFunction from 'src/hooks/refreshFunction/useRefreshFunction';
import DownloadPopup from "src/layout/footer/DownloadPopup"
import { onMobile } from "src/tools/utils/appUtil";
import { isAnylocalApp } from "src/tools/utils/appUtil"
import BackToTop from "src/components/button/BackToTop";

const IsOnTableOrMobile = onTableOrMobile();

const IsOnMobile = onMobile();

const DefaultLayout = () => {
  useRefreshFunction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const location = useLocation();
  const [searchParams] = useSearchParams();
  // const params = new URLSearchParams(location.search);
  const global = useMemo(() => {
    return searchParams.get('global');
  }, [searchParams])
  // params.get('global');
  const isInIframe = useMemo(() => {
    return window !== window.top
  }, [])

  const isGlobalSource = useMemo(() => {
    // return window.location.host.endsWith("cn") && global === "1";
    return global === "1";
  }, [global])

  const iframeSrc = useMemo(() => {
    if (isGlobalSource) {
      return window.location.href.replace(".cn", ".com").replace("global=1", "iframe=1");
    } else {
      return window.location.href;
    }
  }, [isGlobalSource])

  const showOpenApp = useMemo(() => {
    return (isMobile || IsOnTableOrMobile)
      && !isInIframe
      && !hideOpenAppSet.has(window.location.pathname)
    // && (!isWebview() || isBaiduBoxApp)
    // && !(isWeixinBrowser && window.location.host !== WEBSITE_PRODUCTION_CN)
  }, [isMobile, isInIframe]);


  const isMobileOrTable = useMemo(() => {
    return isMobile || IsOnTableOrMobile;
  }, [isMobile])

  // const showDownloadPopup = useMemo(() => {

  // }, [])

  return (
    <Box className='body' sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

      <RecommendIcon />

      <BackToTop/>

      {/* 手机布局显示打开app的选项 */}
      {showOpenApp && <OpenAppBox />}

      {IsOnMobile && !isAnylocalApp() && <DownloadPopup />}

      {isGlobalSource ?
        <iframe id="globalFrame" src={iframeSrc} title="content" style={{ border: "none", width: "100%", height: "100dvh" }} />
        :
        <>
          <Header />
          <Box
            style={{
              minHeight: `calc(100dvh - ${isMobileOrTable ? '0px' : PC_HEADER_HEIGHT} - ${isMobileOrTable ? OpenAppBoxHeight : '0px'})`,
              top: `calc(${isMobileOrTable ? '0px' : PC_HEADER_HEIGHT} + ${isMobileOrTable ? OpenAppBoxHeight : '0px'})`,
            }}
            sx={{
              height: "auto",
              position: "relative",
              background: '#f9f9f9',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                flex: 1
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </>
      }
    </Box>
  )
}

export default DefaultLayout;