import { useCallback, useEffect, useState } from "react";
import CommonDialogBox from "../box/CommonDialogBox";
import { useTranslation } from "react-i18next";
import { Box, TextField, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/esm/mui";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "src/tools/toolkit/store";
import { useDispatch } from "react-redux";
import { setCloseFeedbackDialog } from "src/service/redux/feedback-slice";
import Radio from "@mui/material/Radio";
import SelectFileBox from "src/components/dialog/feedback/SelectFileBox";
import {
  useGetFeedbackMenuListMutation,
  useCreateFeedbackMutation,
} from "src/service/api/feedback-api";
import { ChangeFeedbackMenu, CreateFeedbackProps } from "src/models/feedback";

const useStyles = makeStyles()((theme) => {
  return {
    item: {
      display: "flex",
      alignItems: "flex-start",
      margin: "20px 0",
    },
    title: {
      fontSize: "14px",
      fontWeight: 500,
      width: "100px",
      marginTop: "5px",
      flexShrink: 0,
    },
  };
});

const FeedbackDialog = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { showFeedbackDialog } = useSelector((state) => state.feedback);
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");
  const [isNext, setIsNext] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [contentError, setContentError] = useState<string>("");
  const [mediaList, setMediaList] = useState<string[]>([""]);
  const [getFeedbackMenuList, { isLoading: loading }] =
    useGetFeedbackMenuListMutation();
  const [feedbackMenuList, setFeedbackMenuList] = useState<
    ChangeFeedbackMenu[]
  >([]);
  const [create, { isLoading: createLoading }] = useCreateFeedbackMutation();

  const handleGetFeedbackMenuList = useCallback(() => {
    getFeedbackMenuList({
      request_type: "feedback_type",
    })
      .unwrap()
      .then((res) => {
        if (!res?.isFailed) {
          const feedbackObj = res.result;
          const changeFeedbackMenu: ChangeFeedbackMenu[] = Object.entries(
            feedbackObj
          ).map(([key, value]) => ({
            id: key,
            cn: value.display_name_cn,
            en: value.display_name_en,
          }));
          setFeedbackMenuList(changeFeedbackMenu);
        } else {
          setFeedbackMenuList([]);
        }
      });
  }, [getFeedbackMenuList]);

  useEffect(() => {
    handleGetFeedbackMenuList();
  }, [handleGetFeedbackMenuList]);

  const handleReset = () => {
    handleCancel();
    setSelectedQuestion("");
    setIsNext(false);
    setMediaList([""]);
    setContentError("");
    setContent("");
  };

  const handleCancel = () => {
    dispatch(setCloseFeedbackDialog());
  };

  const handleBack = () => {
    setIsNext(false);
  };

  const handleNext = () => {
    if (!selectedQuestion) {
      enqueueSnackbar(t("app.settings.feedback.select_question"), {
        variant: "warning",
      });
      return;
    }
    setIsNext(true);
  };

  const handleConfirm = () => {
    const pictureList = mediaList.slice(1);
    if (!content) {
      setContentError(t("app.settings.feedback.content_tip"));
      return;
    }
    let param: CreateFeedbackProps = {
      request_type: "create",
      request_data: {
        question: content,
        type: selectedQuestion,
      },
    };
    if (pictureList?.length > 0) {
      param.request_data.pictures = pictureList;
    }
    create(param)
      .unwrap()
      .then((res) => {
        if (!res?.isFailed) {
          enqueueSnackbar(t("app.settings.feedback.create_success"), {
            variant: "success",
          });
          handleReset();
        }
      });
  };

  const handleRadioChange = (item: ChangeFeedbackMenu) => {
    setSelectedQuestion(item.id);
  };

  const handleChange = (event) => {
    setContentError("");
    if (event.target.value.length <= 1000) {
      setContent(event.target.value);
    }
  };

  const handleSelectMedia = (url: string) => {
    if (mediaList.includes(url)) {
      return;
    }
    setMediaList([...mediaList, url]);
  };

  const handleRemoveMedia = (url: string) => {
    setMediaList(mediaList.filter((item) => item !== url));
  };

  return (
    <CommonDialogBox
      open={showFeedbackDialog}
      handleClose={handleCancel}
      handleCancel={isNext ? handleBack : handleCancel}
      handleConfirm={isNext ? handleConfirm : handleNext}
      confirmText={
        isNext
          ? t("app.settings.feedback.confirm")
          : t("app.settings.feedback.next")
      }
      dialogTitle={t("app.settings.feedback.feedback")}
      cancelText={
        isNext
          ? t("app.settings.feedback.back")
          : t("app.settings.feedback.cancel")
      }
      loading={createLoading}
      width="800px"
    >
      {isNext ? (
        <Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>
              {t("app.settings.feedback.question_title")}
            </Typography>
            <TextField
              value={content}
              onChange={handleChange}
              sx={{
                width: "100%",
                overflow: "auto",
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
                textarea: {
                  resize: "vertical",
                },
              }}
              placeholder={`${t("app.settings.feedback.question_des")}...`}
              multiline
              rows={3}
              error={Boolean(contentError)}
              helperText={contentError}
            />
          </Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>
              {t("app.settings.feedback.pictures")}
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {mediaList?.map((item) => {
                return (
                  <SelectFileBox
                    key={item}
                    handelAddMediaUrl={(url: string) => handleSelectMedia(url)}
                    handleRemoveMediaUrl={(url: string) =>
                      handleRemoveMedia(url)
                    }
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              fontFamily: "Helvetica Neue",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "17.09px",
              textAlign: "left",
              color: "#999999",
              margin: "15px 0",
            }}
          >
            {t("app.settings.feedback.desc")}
          </Typography>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                height: "324px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                maxHeight: "500px",
                overflow: "scroll",
              }}
            >
              {feedbackMenuList?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {i18n.language === "zh-CN" ? item.cn : item.en}
                    </Typography>
                    <Radio
                      checked={selectedQuestion === item?.id}
                      onChange={() => handleRadioChange(item)}
                    />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </CommonDialogBox>
  );
};

export default FeedbackDialog;
