import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { baseQueryWithReauth, buildQueryWithReAuth } from "../../tools/toolkit/helper";
import { Salt, UserProfile, UserProfileProps } from "../../models/user";

// const baseQuery = buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL);


export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      generateQRCode: builder.mutation<Result<any>, { locationDesc: string }>({
        query: ({ locationDesc }) => ({
          url: `qr_code`,
          method: "POST",
          body: {
            request_type: "generate",
            request_data: {
              location: locationDesc,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      // called by service side after scan qr code
      generateToken: builder.mutation<Result<any>, { uuid: string }>({
        query: ({ uuid }) => ({
          url: `qr_code`,
          method: "POST",
          body: {
            request_type: "update",
            request_data: {
              uuid: uuid,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      loginByQRCode: builder.mutation<Result<any>, { uuid: string }>({
        query: ({ uuid }) => ({
          url: `qr_code`,
          method: "POST",
          body: {
            request_type: "get",
            request_data: {
              uuid: uuid,
            },
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      refreshToken: builder.mutation<
        Result<{ token: string; refresh_token: string }>,
        { userId: string; refreshToken: string }
      >({
        query: ({ userId, refreshToken }) => ({
          url: `signin`,
          method: "POST",
          body: { user_id: userId, refresh_token: refreshToken },
        }),
        transformResponse: (
          res: Response<{ token: string; refresh_token: string }>
        ) => new Value(res),
      }),

      getUserProfile: builder.mutation<
        Result<{ user_profile: UserProfile }>,
        UserProfileProps
      >({
        query: (props) => ({
          url: props.baseUrl ? `${props.baseUrl}/getuserprofile` : "/getuserprofile",
          method: "POST",
          body: {
            user_id:props.userId,
            source:props.source
          },
        }),
        transformResponse: (res: Response<{ user_profile: UserProfile }>) =>
          new Value(res),
      }),

      getPasscode: builder.mutation<Result<Salt>, { userId: string }>({
        query: ({ userId }) => ({
          url: `signinpw`,
          method: "POST",
          body: { user_id: userId },
        }),
        transformResponse: (res: Response<Salt>) => new Value(res),
      }),

      loginByPassword: builder.mutation<
        Result<{ token: string; refresh_token: string; type?: number; auth_addr?:string }>,
        {
          userId: string;
          passwordHash: string;
          gps: string;
          serverSalt?: string;
          pubKey?: string;
          signature?: string;
          verify_code?:string
        }
      >({
        query: ({
          userId,
          passwordHash,
          gps,
          serverSalt,
          pubKey,
          signature,
          verify_code
        }) => ({
          url: "signinpw",
          method: "POST",
          body: {
            user_id: userId,
            password_hash: passwordHash,
            phone_model: "web",
            gps,
            public_key: pubKey,
            server_salt: serverSalt,
            signature,
            verify_code
          },
        }),
        transformResponse: (
          res: Response<{ token: string; refresh_token: string; type?: number; auth_addr?: string }>
        ) => new Value(res),
      }),
      resetPassword: builder.mutation<
        Result<any>,
        {
          client_salt: string;
          password_hash: string;
          new_password_hash: string;
        }
      >({
        query: ({ client_salt, password_hash, new_password_hash }) => ({
          url: "resetpassword",
          method: "POST",
          body: {
            client_salt,
            password_hash,
            new_password_hash,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      forgetPasswordSendCode: builder.mutation<
        Result<any>,
        {
          user_id: string;
        }
      >({
        query: ({ user_id }) => ({
          url: "forgetpassword",
          method: "POST",
          body: {
            user_id,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
      forgetPasswordResetPassword: builder.mutation<
        Result<any>,
        {
          user_id: string;
          client_salt: string;
          password_hash: string;
          code: string;
        }
      >({
        query: ({ user_id, client_salt, password_hash, code }) => ({
          url: "forgetpassword",
          method: "POST",
          body: {
            user_id,
            client_salt,
            password_hash,
            code,
          },
        }),
        transformResponse: (res: Response<any>) => new Value(res),
      }),
    };
  },
});

export const {
  useGenerateQRCodeMutation,
  useGenerateTokenMutation,
  useLoginByQRCodeMutation,
  useRefreshTokenMutation,
  useGetUserProfileMutation,
  useGetPasscodeMutation,
  useLoginByPasswordMutation,
  useResetPasswordMutation,
  useForgetPasswordSendCodeMutation,
  useForgetPasswordResetPasswordMutation,
} = authApi;
