import { FC, ReactNode } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";
import logo from 'src/images/logo/logo72.png';

import { fetchWithErrorHandling, ErrorType, ErrorSubType, getExtraData } from "src/tools/utils/reportError";


const handleOnError = (error: Error, stack: { componentStack: string }) => {

  fetchWithErrorHandling({
    type: ErrorType.ERROR,
    sub_type: ErrorSubType.CLIENT_ERROR,
    content: JSON.stringify(error.message),
    description: JSON.stringify({
      componentStack: stack.componentStack,
      ...getExtraData()
    }),
  })
}



const ErrorPage = () => {

  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = '/';
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '25px'
      }}
    >
      <Box component='img' src={logo} />
      <Box
        sx={{
          fontFamily: 'Helvetica Neue',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >
        {t('app.error-boundary.title')}
      </Box>
      <Button
        onClick={handleClick}
        sx={{
          height: "40px",
          textTransform: 'none',
          borderRadius: "20px",
          background: "linear-gradient(90deg, #60D6FF 0%, #3A92F9 100%)",
          border: 0,
          color: 'white',
          p: '0 20px'
        }}
      >
        {t('app.error-boundary.button')}
      </Button>
    </Box>
  )
}

interface Props {
  children: ReactNode;
}

const ErrorPageComponent: FC<Props> = ({ children }) => <ErrorBoundary FallbackComponent={ErrorPage} onError={handleOnError} >{children}</ErrorBoundary>

export default ErrorPageComponent;