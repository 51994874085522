import { IOSAppUrlScheme, AndroidHrefStart, AndroidHrefEnd } from "src/tools/utils/openApp";
import { isIOS, isAndroid } from "src/tools/utils/appUtil";
import { paths } from "src/app-config";
import { Base64 } from "js-base64";

export const getOpenAppLink = (id: string): string => {
  if (isIOS) {
    return `${IOSAppUrlScheme}?type=chatroom_join&room_id=${id}`;
  } else if (isAndroid) {
    return `${AndroidHrefStart}S.room_id=${id};S.message_type=12;S.type=join;${AndroidHrefEnd}`;
  }
  return "";
}


export const privateRoomPaths = {
  visit: '/visit',
  join: '/join/:id',
  auditApply: '/audit-apply/:id',
  chatroom: '/:id',
  list: '/',
}



export const getChatroomLink = (id: string): string => {
  return `${paths.privateRoom}${privateRoomPaths.chatroom.replace(':id', id)}`
}

export const getChatroomVisitLink = (id: string): string => {
  return `${paths.privateRoom}${privateRoomPaths.visit}?id=${Base64.encodeURI(id)}`;
}

export const getChatRoomAuditApplyLink = (id: string): string => {
  return `${paths.privateRoom}${privateRoomPaths.auditApply.replace(':id', id)}`
}