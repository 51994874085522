import { RewardPaidInfo } from "./reward";
import { PaymentCurrency } from "./payment";
import { RequestEntity, RequestPostResponseEntity } from "./request";
import { CommonUser, SimpleUser } from "./user";
import DefaultAvatar from "src/images/defaultIcons/default-avatar.png";
import { appConfig } from "src/app-config";


export const ANONYMOUS_USER = 'Anonymous';

interface translatedProps {
  accept_rewards: boolean;
  description: string;
  for_sell: boolean;
  mention: any[];
  price: number;
  show_anonymous: boolean;
  timezone: string;
  title: string;
  topic: any[];
  type: string;
  uri: UriEntity[];
  location_name: string;
}

interface tagsProps {
  cn_name: string;
  name: string;
  tag_id: string;
}

export interface PostEntity {
  commentcount: number;
  gps: string; //"34.19450798913586, 108.87621593292855"
  language: string;
  loc_id: string;
  loc_name: string;
  message_content: PostMessageContent;
  message_hash: string;
  message_type: MessageTypeEnum.Message;
  messageid: string;
  parent_message_id: string;
  popular_weight: number;
  sub_location_id: string;
  thumb_from_you: boolean;
  thumbdown: number;
  thumbup: number;
  timestamp: number;
  user_privilege: number;
  useravatar: string;
  user_avatar: string;
  userid: string;
  user_id: string;
  username: string;
  user_name: string;
  personalized_signature: string;
  product?: {
    title: string;
    id: string;
  };
  owner?: {
    user_avatar: string;
    user_id: string;
    user_name: string;
    type: string;
    can_subscribe: boolean; // 表示这个用户可不可以被订阅
  };
  viewcount: number;
  request_amount: string;
  request_leavemessage: string;
  request_price: string;
  translated_message?: {
    en: translatedProps;
  };
  source_region: string;
  uri_index?: number; //收藏单张照片会有这个值
  location_number: string;
  offline?: boolean;
  tags: tagsProps[]
  across_commentcount: number;
  across_sharecount: number;
  across_thumbdown: number;
  across_thumbup: number;
  across_viewcount: number;
  similar?: PostEntity[];
  isExpand?: boolean;
  share_to_loc_room?: boolean;
  is_subscribed_post: boolean; // 这个帖子是否被订阅
  is_subscribed_user: boolean; // 这个user是否被订阅
  can_view: boolean; // 可不可以看
}

export interface RecommendProps {
  contentType: string;
  geoHash?: string;
  pageSize?: number;
  startKey?: string;
  forwardOrder?: boolean;
  sort_type?: string;
  deviceId?: string;
  id_list?: string[];
}

export interface getLocationPostListProps {
  pageSize: number;
  startKey: string;
  civil_level?: string;
  messageid?: string;
  gps?: string;
  area?: string;
  forwardOrder?: boolean;
}

export interface getTopicListProps {
  pagesize: number;
  topic_id: string,
  startkey: string
}

export interface getTagListProps {
  pageSize: number;
  tagId: string,
  startkey: string
}

export interface AllPostResponse {
  messages: PostEntity[];
  start_key: string;
}

export interface recommendPostResponse {
  messages: PostEntity[];
  startkey: string;
}

export interface SendPostEntity {
  request_type: string;
  request_data: {
    loc_id: string;
    message: PostMessageContent;
    gps: string; //"34.19450798913586, 108.87621593292855"
    timestamp: number;
    message_type: MessageTypeEnum.Message;
  };
}

export interface LiveBroadcastEntity {
  broadcast: BroadcastInfo;
  commentcount: number;
  gps: string; //"34.19450798913586, 108.87621593292855"
  language: string;
  loc_id: string;
  loc_name: string;
  message_type: MessageTypeEnum.LiveBroadcast;
  messageid: string;
  popular_weight: number;
  sub_location_id: string;
  thumb_from_you: boolean;
  thumbdown: number;
  thumbup: number;
  timestamp: number;
  user_privilege: number;
  useravatar: string;
  userid: string;
  username: string;
}

export interface BroadcastInfo {
  broadcast_channel: {
    authorized: boolean;
    channel_arn: string;
    channel_name: string;
    channel_region: string;
    channel_type: string;
    enable_playback: boolean;
    ingest_endpoint: string;
    latency_mode: string;
    playback_url: string;
    push_url: string;
    replay_url: string;
    streamKey_arn: string;
    streamKey_value: string;
  };
  broadcast_fees: number;
  broadcast_real_status: boolean;
  broadcast_start_time: number;
  broadcast_status: BroadcastStatusEnum; //1-end, 2-created, 3-start
  broadcast_title: string;
  broadcast_cover: string;
}

export enum BroadcastStatusEnum {
  END = 1,
  Created = 2,
  Start = 3,
}

export enum ChatTypeEnum {
  chatroom = "chatroom",
  oneToOne = "one_to_one"
}

export enum NotificationTypeTypeEnum {
  mention = "mention",
  reply = "reply"
}

export enum MessageTypeEnum {
  Message = 1,
  Request = 2,
  Response = 3,
  Request_Response = 4,
  LiveBroadcast = 5,
  Admin_Recommend = 6
}

export enum PostMediaType {
  Unknown = -1,
  Text = 1,
  Photo = 2,
  Video = 3,
}

export enum ThumbType {
  Message = 1,
  User = 2,
}

// type,1:文本，2:图片，3:视频
export interface NoteMedia {
  width?: number;
  height?: number;
  url: string;
  type?: 1 | 2 | 3;
}


export interface UriEntity {
  compressed_url: string;
  hash?: string;
  thumb_from_you?: boolean;
  thumb_up?: number;
  timestamp?: number;
  type: PostMediaType;
  url: string;
  width?: number;
  height?: number;
  size?: number;
  gps?: string;
  verified?: boolean;
  sign?: string;
  sign_time?: number;
  device_model?: string;
  ai_description?: string;
  notes?: string;
  fuzzy_url?: string; //模糊图
  duration?: number; //视频总时长
  note_medias?: NoteMedia[]; // 图片或视频描述，暂时只有一项
  pay_to_watch_free? :boolean; // 表示是否付费
  quality?: string;
}

export interface mediaWH {
  width: number;
  height: number;
}

export interface RecommendResponse {
  itemList: PostEntity[];
  startKey: string
}

export const getShownUserAvatarFromPost = (post: PostEntity | undefined) => {
  if (!post) {
    return DefaultAvatar;
  }
  if (ANONYMOUS_USER === post.username) {
    return DefaultAvatar;
  } else if (post?.owner?.user_id === post.userid) {
    return post.useravatar || DefaultAvatar;
  } else {
    return post?.owner?.user_avatar || DefaultAvatar;
  }
};

export const getShownUserNameFromPost = (
  post: PostEntity | undefined,
  t: any
) => {
  if (!post) {
    return "";
  }
  if (ANONYMOUS_USER === post.username) {
    return post.username;
  } else if (post.owner) {
    if (post.owner?.user_id === post.userid) {
      return post.username || post.userid;
    } else {
      if (post.owner?.user_name) {
        return `${post.owner?.user_name}(${t("app.post.owner")})`;
      } else {
        return `${post.owner?.user_id}(${t("app.post.owner")})`;
      }
    }
  } else {
    return post.username || post.userid;
  }
};

export const getLatitudeFromMessageGps = (gps: string | undefined) => {
  if (!gps) {
    return 0;
  }
  const array = gps.split(",");
  if (array.length > 1) {
    const lat = array[0].replace(" ", "");
    return parseFloat(lat);
  }
  return 0;
};

export const getLongitudeFromMessageGps = (gps: string | undefined) => {
  if (!gps) {
    return 0;
  }
  const array = gps.split(",");
  if (array.length > 1) {
    const lon = array[1].replace(" ", "");
    return parseFloat(lon);
  }
  return 0;
};

export const getPostLocationName = (locName: string | undefined) => {
  if (!locName) {
    return "";
  }
  const index = locName.indexOf(",");
  return index === -1 ? locName : locName.substring(0, index);
};

export const getPostLocationSubName = (locName: string | undefined) => {
  if (!locName) {
    return "";
  }
  const index = locName.indexOf(",");
  return index === -1
    ? locName
    : locName.length > index + 1
      ? locName.substring(index + 1)
      : locName;
};

export const getPictureMediaList = (
  post: PostEntity | undefined
): UriEntity[] => {
  if (!post || !post.message_content) {
    return [];
  }
  const urlObjs = post.message_content.uri;
  if (urlObjs?.length > 0) {
    const picUrls: UriEntity[] = [];
    urlObjs.forEach((item) => {
      if (item.type === PostMediaType.Photo) {
        picUrls.push(item);
      }
    });
    return picUrls;
  }
  return [];
};

export const getPictureUrlList = (post: PostEntity | undefined) => {
  if (!post || !post.message_content) {
    return [];
  }
  const urlObjs = post.message_content.uri;
  if (urlObjs?.length > 0) {
    const picUrls: string[] = [];
    urlObjs.forEach((item) => {
      if (item.type === PostMediaType.Photo) {
        picUrls.push(item.url || item.compressed_url);
      }
    });
    return picUrls;
  }
  return [];
};

export const getVideoUrl = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.url;
  } else {
    return "";
  }
};

export const getVideoWidthHeight = (
  post: PostEntity | RequestPostResponseEntity | undefined
): mediaWH | null => {
  if (!post || !post.message_content) {
    return null;
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return null;
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return {
      width: videoItem.width || 0,
      height: videoItem.height || 0,
    };
  } else {
    return null;
  }
};

export const getMediaDirection = (uri: UriEntity | undefined) => {
  if (!uri || !uri.width || !uri.height) {
    return "vertical";
  }

  return uri.width > uri.height ? "horizontal" : "vertical";
};

export const getVideoVerified = (post: PostEntity | undefined): boolean => {
  if (!post || !post.message_content) {
    return false;
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return false;
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.verified || false;
  } else {
    return false;
  }
};

export const getVideoHash = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.hash || "";
  } else {
    return "";
  }
};

export const getVideoTimestamp = (post: PostEntity | undefined) => {
  if (!post || !post.message_content) {
    return post?.timestamp;
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return post?.timestamp;
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.timestamp || post?.timestamp;
  } else {
    return post?.timestamp;
  }
};

export const getVideoCoverImageUrl = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const videoItem = uris.find((item) => item.type === PostMediaType.Video);
  if (videoItem) {
    return videoItem.compressed_url;
  } else {
    return "";
  }
};

export const getCoverImage = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  return getCoverImageFromPostContent(post.message_content);
};

export const getCoverImageFromPostContent = (
  postContent: PostMessageContent | undefined
): string => {
  if (!postContent) {
    return "";
  }
  const uris: UriEntity[] = postContent.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const item0 = uris[0];
  if (item0.type === PostMediaType.Photo) {
    return item0.compressed_url || item0.url;
  } else if (item0.type === PostMediaType.Video) {
    return item0.compressed_url;
  } else {
    return "";
  }
};

export const getCoverImageHash = (post: PostEntity | undefined): string => {
  if (!post || !post.message_content) {
    return "";
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return "";
  }
  const item0 = uris[0];
  return item0.type === PostMediaType.Photo ||
    item0.type === PostMediaType.Video
    ? item0?.hash || ""
    : "";
};

export const getImageWidthHeight = (
  post: PostEntity | undefined
): mediaWH | null => {
  if (!post || !post.message_content) {
    return null;
  }
  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return null;
  }
  const imageItem = uris.find((item) => item.type === PostMediaType.Photo);
  if (imageItem) {
    return {
      width: imageItem.width || 0,
      height: imageItem.height || 0,
    };
  } else {
    return null;
  }
};

export const getPostMediaType = (
  post: PostEntity | undefined
): PostMediaType => {
  if (!post || !post.message_content) {
    return PostMediaType.Unknown;
  }

  const uris: UriEntity[] = post.message_content.uri;
  if (!uris || uris.length === 0) {
    return PostMediaType.Text;
  }
  return uris[0].type;
};

// export const getPostDeviceModel = (
//   post: PostEntity | RequestPostResponseEntity
// ): string => {
//   if (!post.message_content) {
//     return "";
//   }

//   const uris: UriEntity[] = post.message_content.uri;
//   if (!uris || uris.length === 0) {
//     return "";
//   }
//   return uris[0].device_model || "";
// };

export const getPlayUrl = (liveInfo: BroadcastInfo | undefined) => {
  if (!liveInfo) {
    return;
  }
  if (
    liveInfo?.broadcast_status === BroadcastStatusEnum.Start &&
    liveInfo?.broadcast_channel?.playback_url
  ) {
    return liveInfo?.broadcast_channel?.playback_url;
  }
  if (liveInfo?.broadcast_channel?.replay_url) {
    return liveInfo?.broadcast_channel?.replay_url;
  }
  return "";
};

export const isLiving = (liveInfo: BroadcastInfo | undefined): boolean => {
  if (!liveInfo) {
    return false;
  }
  return (
    liveInfo.broadcast_status === BroadcastStatusEnum.Start &&
    !!liveInfo.broadcast_channel?.playback_url
  );
};

interface topicList {
  topic_id: string
}

interface referenceList {
  type: MessageTypeEnum.Message
  id: string
}

export interface PostMessageContent {
  title: string;
  description: string;
  mention?: any[];
  topic?: topicList[];
  type: string;
  uri: UriEntity[];
  paidInfo?: RewardPaidInfo;
  for_sell?: boolean;
  price?: number;
  currency?: string;
  external_link: string;
  appointed_purchaser?: CommonUser;
  show_anonymous?: boolean;
  timezone: string;
  reference?: referenceList[];
  pay_to_watch?: boolean; //是否是付费观看
  expire_timestamp?: number;// 过期时间
  free_count?: number; // 表示视频前几秒免费, 表示前几张照片免费
}


export const POST_EXPIRE_LIMIT = process.env.REACT_APP_ENV?.includes("prod") ? 300 : 60;

export enum TradeStatusEnum {
  Unstart = 1,
  Ongoing = 2,
  End = 3,
}

export enum FilterTypeEnum {
  Popular = 1,
  Nearby = 2,
  Latest = 3,
  Amount = 4
}

export enum TradeTypeEnum {
  Reward = "reward",
  Post = "post",
  Request = "request",
}

export enum TradeMarketSortEnum {
  ByTime = 1,
  ByAmount = 2,
}

export interface TradeMarketEntity {
  amount: string;
  content_id: string;
  content_info: PostEntity;
  content_type: string;
  currency: PaymentCurrency;
  payee?: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  };
  payer?: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  };
  timestamp: number;
  trade_status: TradeStatusEnum;
  trade_type: TradeTypeEnum;
}

export interface TradeMarketRequestEntity {
  amount: string;
  content_id: string;
  content_info: RequestEntity;
  content_type: string;
  currency: PaymentCurrency;
  distance: number;
  payee?: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  };
  payer?: {
    user_avatar: string;
    user_id: string;
    user_name: string;
  };
  timestamp: number;
  trade_status: TradeStatusEnum;
  trade_type: TradeTypeEnum;
  primary_key: string;
}

export interface FilterOptions {
  id: number;
  value: string;
}

export interface AreaProps {
  name: string;
  loc_id: string;
  isCloseSend?: boolean
}

export interface PurchaseRequestEntity {
  owner_user_id: string;
  message_id: string;
  request_user_id: string;
  request_price: string;
  timestamp: string;
  description: string;
  status: "pending" | "accepted" | "rejected";
  owner_user: SimpleUser;
  request_user: SimpleUser;
}

//支持举报user,comment,post,collection,request,broadcast六种内容类型
export enum ReportContentTypeEnum {
  User = "user",
  Comment = "comment",
  Post = "post",
  Collection = "collection",
  Request = "request",
  Broadcast = "broadcast",
}

export const ReferencePostString = {
  "en": "Referenced Post",
  "zh-CN": "引用的帖子"
}

export const addReferenceLink = (text: string, referenceList: referenceList[]) => {
  if (referenceList.length === 0) {
    return text;
  }

  const enReferencePostStr = ReferencePostString['en'];
  const zhCNReferencePostStr = ReferencePostString['zh-CN'];
  const regexPattern = new RegExp(`&(${enReferencePostStr}|${zhCNReferencePostStr})["\u{2005}"]`, 'gu');

  let matchIndex = 0;
  let replacedText = text.replace(regexPattern, (match) => {
    if (matchIndex < referenceList.length) {
      const referenceId = referenceList[matchIndex]?.id;
      matchIndex++;
      const href = `${appConfig.paths.details}?id=${referenceId}`;
      return `<a href="${href}" target="_blank" style="text-decoration: none; color: #3A92F9;">${match}</a>`;
    } else {
      return match;
    }
  });

  return replacedText;
}

export const addMentionLink = (text: string, mentionList) => {
  if (mentionList.length === 0) {
    return text;
  } else {
    // @ts-ignore
    const regex = /@([^@\u{2004}]+)["\u{2004}"]/gu;
    let replacedText = text;
    let match;
    while ((match = regex.exec(text)) !== null) {
      const mention = match[1];
      const foundMentionUser = mentionList.find(user => user.user_name === mention || user.user_id === mention)
      if (foundMentionUser) {
        const replacement = `<a href="${appConfig.paths.user}/${foundMentionUser.user_id}" target="_blank" style="text-decoration: none; color: #3A92F9;">${match[0]}</a>`;
        replacedText = replacedText.replace(match[0], replacement);
      }
    }
    return replacedText;
  }
}

// 获取媒体类型/video/picture
export const getMediaType = (uri: UriEntity) => {
  if (!uri?.type) {
    return PostMediaType.Unknown;
  } else {
    return uri.type;
  }
}

// 获取媒体设备型号
export const getPostDeviceModel = (uri: UriEntity) => {
  return uri?.device_model || "";
}

// 获取url
export const getMediaUrl = (uri: UriEntity)=>{
  return uri?.url || "";
}

//获取视频封面
export const getVideoCover = (uri: UriEntity)=>{
  return uri?.compressed_url || "";
}

// 获取视频size
export const getVideoSize = (uri: UriEntity)=>{
  return {
    width: uri.width || 0,
    height: uri.height || 0,
  }
}